<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-service-account-form"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ actionTitle }}</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>

                    <form autocomplete="off" aria-autocomplete="off">
                        <div class="modal-body">
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Nome</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="serviceAccount.name"
                                            placeholder="Nome da sua conta de serviço"
                                            id="serviceAccountNameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Tipo de autenticação</label
                                        >
                                        <select
                                            class="form-select"
                                            v-model="serviceAccount.auth_type"
                                            id="serviceAccountTypeSelect"
                                        >
                                            <option value="bearer_token">
                                                Bearer Token
                                            </option>
                                            <option value="http_basic">
                                                HTTP Basic
                                            </option>
                                            <option value="jwt">JWT</option>
                                            <option value="smtp_account">
                                                SMTP Account
                                            </option>
                                            <option value="database_uri">
                                                Database Connection URI
                                            </option>
                                            <option value="oauth2">
                                                OAuth2
                                            </option>
                                            <option value="trello_oauth2">
                                                Trello
                                            </option>
                                            <option value="slack_oauth2">
                                                Slack
                                            </option>
                                            <option value="google_oauth2">
                                                Google
                                            </option>
                                            <option value="aws_access_key">
                                                AWS (Access Key)
                                            </option>
                                            <option value="floui_token">
                                                Floui Token
                                            </option>
                                            <option value="sankhya_partner_id">
                                                Sankhya ID (Parceiro Sankhya)
                                            </option>
                                            <option value="omie_erp">
                                                Omie ERP
                                            </option>
                                            <option value="ftp_server">
                                                FTP Server
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'http_basic'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >URL do serviço</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="serviceAccount.url"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                    id="serviceAccountUrlInput"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Usuário</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="http_basic.username"
                                            placeholder="Usuário"
                                            id="serviceAccountUsernameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Senha</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="http_basic.password"
                                            placeholder="Senha"
                                            id="serviceAccountPasswordInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'aws_access_key'"
                        >
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Access Key ID</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="
                                                aws_access_key.access_key_id
                                            "
                                            placeholder="Usuário"
                                            id="serviceAccountAccessKeyIdInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Secret Access Key</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="
                                                aws_access_key.secret_access_key
                                            "
                                            placeholder="Senha"
                                            id="serviceAccountSecretAccessKeyInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="
                                serviceAccount.auth_type == 'sankhya_partner_id'
                            "
                        >
                            <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required"
                                            >AppKey de Parceiro Sankhya</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="sankhya_partner_id.appkey"
                                            placeholder="AppKey"
                                            id="serviceAccountSankhyaPartnerIdAppKeyInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Token</label
                                        >
                                        <input
                                            type="token"
                                            class="form-control"
                                            v-model="sankhya_partner_id.token"
                                            placeholder="Token"
                                            id="serviceAccountSankhyaPartnerIdTokenInput"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Usuário</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="
                                                sankhya_partner_id.username
                                            "
                                            placeholder="Usuário"
                                            id="serviceAccountSankhyaPartnerIdUsernameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Senha</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="
                                                sankhya_partner_id.password
                                            "
                                            placeholder="Senha"
                                            id="serviceAccountSankhyaPartnerIdPasswordInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="
                                serviceAccount.auth_type == 'omie_erp'
                            "
                        >
                            <div class="mb-3">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required"
                                            >App Key</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="omie_erp.app_key"
                                            placeholder="App Key"
                                            id="serviceAccountOmieErpAppKeyInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >App Secret</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="omie_erp.app_secret"
                                            placeholder="App Secret"
                                            id="serviceAccountOmieErpAppSecretInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'jwt'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >URL do serviço</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="serviceAccount.url"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                    id="serviceAccountUrlInput"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >URL de autenticação</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="jwt.auth_url"
                                            placeholder="Exemplo: https://seuservico.com.br/auth"
                                            id="serviceAccountJwtAuthUrlInput"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >HTTP Method</label
                                        >
                                        <select
                                            class="form-select"
                                            v-model="jwt.method"
                                            id="jwtMethodSelect"
                                        >
                                            <option value="POST">POST</option>
                                            <option value="PUT">PUT</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >TTL / Expiration (seconds)</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="jwt.ttl"
                                            id="jwtTtlInput"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Usuário</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="jwt.username"
                                            placeholder="Usuário"
                                            id="serviceAccountJwtUsernameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Senha</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="jwt.password"
                                            placeholder="Senha"
                                            id="serviceAccountJwtPasswordInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'oauth2'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >URL de autenticação</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="oauth2.authorize_url"
                                    placeholder="Exemplo: https://seu-servico.com.br/oauth2/token"
                                    id="serviceAccountOauth2AuthorizeUrlInput"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Grant Type</label
                                        >
                                        <select
                                            class="form-select"
                                            v-model="oauth2.grant_type"
                                            id="oauth2GrantTypeSelector"
                                        >
                                            <option value="client_credentials">
                                                Client Credentials
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Client ID</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="oauth2.client_id"
                                            placeholder="Client ID"
                                            id="serviceAccountOauth2ClientIdInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Client Secret</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="oauth2.client_secret"
                                            placeholder="Client Secret"
                                            id="serviceAccountOauth2ClientSecretInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'bearer_token'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >URL do serviço</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="serviceAccount.url"
                                    placeholder="Exemplo: https://seu-servico.com.br/rest"
                                    id="serviceAccountUrlInput"
                                />
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Token de autenticação</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="bearer_token.token"
                                            placeholder="*******"
                                            id="serviceAccountBearerTokenInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'floui_token'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >Token (gerado automaticamente)</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="floui_token.token"
                                    placeholder=""
                                    readonly="readonly"
                                    id="serviceAccountFlouiTokenInput"
                                />
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'database_uri'"
                        >
                            <div class="mb-3">
                                <label class="form-label required"
                                    >URI de conexão</label
                                >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="database_uri.uri"
                                    placeholder="mysql://user:password@host:port/database"
                                    id="serviceAccountDatabaseUriInput"
                                />
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'smtp_account'"
                        >
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Host SMTP</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="smtp_account.host"
                                            placeholder="Exemplo: smtp.gmail.com"
                                            id="serviceAccountSmtpHostInput"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Porta</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="smtp_account.port"
                                            placeholder="587"
                                            id="serviceAccountSmtpPortInput"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Usuário</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="smtp_account.username"
                                            placeholder="Usuário"
                                            id="serviceAccountSmtpUsernameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Senha</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="smtp_account.password"
                                            placeholder="Senha"
                                            id="serviceAccountSmtpPasswordInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-body"
                            v-if="serviceAccount.auth_type == 'ftp_server'"
                        >
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-3">
                                        <label class="form-label required"
                                            >Tipo de conexão</label
                                        >
                                        <select
                                            class="form-select"
                                            v-model="ftp_server.server_type"
                                            id="ftpServerTypeSelect"
                                        >
                                            <option value="FTP">FTP</option>
                                            <option value="FTPS">
                                                FTP over TLS
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-9">
                                        <label class="form-label required"
                                            >Host</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="ftp_server.host"
                                            placeholder="Exemplo: ftp.dominio.com"
                                            id="ftpServerHostInput"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Porta</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="ftp_server.port"
                                            placeholder="21"
                                            id="ftpServerPortInput"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required"
                                            >Usuário</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="ftp_server.username"
                                            placeholder="Usuário"
                                            id="ftpServerUsernameInput"
                                        />
                                    </div>
                                    <div class="col">
                                        <label class="form-label required"
                                            >Senha</label
                                        >
                                        <input
                                            type="password"
                                            class="form-control"
                                            v-model="ftp_server.password"
                                            placeholder="Senha"
                                            id="ftpServerPasswordInput"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="modal-footer">
                        <button
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                            id="cancelButton"
                        >
                            Cancelar
                        </button>
                        <button
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                            id="saveAccountServiceButton"
                        >
                            <i class="ti ti-plus"></i>
                            Salvar conta de serviço
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceAccountForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "create"
        },
        formData: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Criar conta de serviço",
            serviceAccount: {
                auth_type: "bearer_token"
            },
            database_uri: {
                uri: ""
            },
            bearer_token: {
                token: ""
            },
            floui_token: {
                token:
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15)
            },
            jwt: {
                auth_url: "",
                username: "",
                password: "",
                method: "post",
                ttl: "3600"
            },
            smtp_account: {
                host: "",
                username: "",
                password: "",
                port: "",
                secure: "1"
            },
            ftp_server: {
                server_type: "FTP",
                host: "",
                port: "",
                username: "",
                password: ""
            },
            http_basic: {
                username: "",
                password: ""
            },
            sankhya_partner_id: {
                appkey: "",
                token: "",
                username: "",
                password: ""
            },
            omie_erp: {
                app_key: "",
                app_secret: ""
            },
            oauth1: {
                consumer_key: "",
                consumer_secret: "",
                request_token_url: "",
                access_token_url: "",
                authorize_url: ""
            },
            oauth2: {
                authorize_url: "",
                grant_type: "client_credentials",
                client_id: "",
                client_secret: ""
            },
            aws_access_key: {
                access_key_id: "",
                secret_access_key: ""
            }
        }
    },
    mounted() {
        if (this.formData != {} && this.formData != null) {
            this.serviceAccount = structuredClone(this.formData)

            // populate auxiliary fields
            this[this.serviceAccount.auth_type] =
                this.serviceAccount[this.serviceAccount.auth_type]
        }

        if (this.action === "edit") {
            this.actionTitle = "Editar conta de serviço"
        }

        var modal = new bootstrap.Modal(
            document.getElementById("modal-service-account-form")
        )
        modal.show()
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            this.serviceAccount = this.clearAuthFields(this.serviceAccount)
            this.serviceAccount[this.serviceAccount.auth_type] =
                this[this.serviceAccount.auth_type]

            this.$emit("save", this.serviceAccount)
        },
        clearAuthFields(object) {
            if (Object.hasOwnProperty("bearer_token")) {
                delete object.bearer_token
            }

            if (Object.hasOwnProperty("database_uri")) {
                delete object.database_uri
            }

            if (Object.hasOwnProperty("floui_token")) {
                delete object.floui_token
            }

            if (Object.hasOwnProperty("jwt")) {
                delete object.jwt
            }

            if (Object.hasOwnProperty("http_basic")) {
                delete object.http_basic
            }

            if (Object.hasOwnProperty("oauth1")) {
                delete object.oauth1
            }

            if (Object.hasOwnProperty("oauth2")) {
                delete object.oauth2
            }

            if (Object.hasOwnProperty("aws_access_key")) {
                delete object.aws_access_key
            }

            if (Object.hasOwnProperty("ftp_server")) {
                delete object.ftp_server
            }

            if (Object.hasOwnProperty("sankhya_partner_id")) {
                delete object.sankhya_partner_id
            }

            if (Object.hasOwnProperty("omie_erp")) {
                delete object.omie_erp
            }

            return object
        }
    }
}
</script>
