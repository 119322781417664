<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row g-2">
                        <div class="col">
                            <FlowsList :workspace="workspace" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import FlowsList from "../components/FlowsList.vue"
import moment from "moment"

export default {
    name: "FlowsPage",
    components: {
        FlowsList
    },
    data() {
        return {
            workspace_id: null
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        this.workspace_id = store.workspace._id
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        }
    }
}
</script>
