<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-database"></i>
                                        Editar tabela
                                    </h3>
                                </div>
                                <div class="col-auto" v-if="!table._id">
                                    <button class="btn" @click="importTableStructure()" id="importTableStructureButton">
                                        <i class="ti ti-upload"></i>
                                        Importar estrutura
                                    </button>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label">Nome da tabela</label>
                                        <input type="text" class="form-control" placeholder="" v-model="table.name"
                                            id="tableNameInput" />
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <h3>Campos da tabela</h3>
                                            </div>
                                        </div>

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Campo</th>
                                                    <th>Label</th>
                                                    <th>Tipo</th>
                                                    <th>Tamanho</th>
                                                    <th>Valor padrão</th>
                                                    <th>É único</th>
                                                    <th>É obrigatório</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>

                                            <draggable tag="tbody" v-model="table.fields" item-key="position"
                                                v-bind="dragOptions" handle=".row-handler" @start="drag = true"
                                                @end="drag = false" @change="updateFieldsPosition()">
                                                <template #item="{
                                    element,
                                    index
                                }">
                                                    <tr>
                                                        <td>
                                                            <i class="ti ti-grip-vertical row-handler"></i>
                                                        </td>
                                                        <td>
                                                            <input type="text" name="name"
                                                                class="form-control form-control-sm fieldNameInput"
                                                                placeholder="Nome do campo" v-model="element.name" />
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                class="form-control form-control-sm fieldLabelInput"
                                                                placeholder="Label do campo" v-model="element.label" />
                                                        </td>
                                                        <td>
                                                            <select class="form-select form-select-sm fieldTypeSelect"
                                                                v-model="element.type">
                                                                <option value="string">
                                                                    Texto
                                                                </option>
                                                                <option value="longtext">
                                                                    Texto longo
                                                                </option>
                                                                <option value="integer">
                                                                    Inteiro
                                                                </option>
                                                                <option value="float">
                                                                    Float
                                                                </option>
                                                                <option value="boolean">
                                                                    Booleano
                                                                </option>
                                                                <option value="date">
                                                                    Data
                                                                </option>
                                                                <option value="datetime">
                                                                    Data e hora
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                class="form-control form-control-sm fieldLengthInput"
                                                                placeholder="" v-model="element.length
                                    " :disabled="element.type !==
                                    'string' &&
                                    element.type !==
                                    'longtext'
                                    " />
                                                        </td>
                                                        <td>
                                                            <input type="text"
                                                                class="form-control form-control-sm fieldDefaultValueInput"
                                                                placeholder="" v-model="element.default_value
                                    " />
                                                        </td>
                                                        <td>
                                                            <input type="checkbox"
                                                                class="form-check-input fieldIsUniqueCheckbox" v-model="element.is_unique
                                    " />
                                                        </td>
                                                        <td>
                                                            <input type="checkbox"
                                                                class="form-check-input fieldIsRequiredCheckbox"
                                                                v-model="element.is_required
                                    " />
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm duplicateColumnButton" @click="
                                    duplicateColumn(
                                        index
                                    )
                                    ">
                                                                <i class="ti ti-copy"></i>
                                                                Duplicar
                                                            </button>
                                                            <span>&nbsp;</span>
                                                            <button class="btn btn-sm btn-ghost-red removeColumnButton"
                                                                @click="
                                    removeColumn(
                                        index
                                    )
                                    ">
                                                                <i class="ti ti-trash"></i>
                                                                Remover
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </draggable>

                                        </table>

                                        <button class="btn text-blue" @click="addColumn()" id="addColumnButton">
                                            <i class="ti ti-plus"></i>
                                            Adicionar campo
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" v-if="validationErrors.length">
                                <div class="row">
                                    <div class="col">
                                        <h5>Erros encontrados:</h5>
                                        <ul>
                                            <li v-for="error in validationErrors" :key="error" class="text-red">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" v-if="this.$route.params.table_id">
                                <div class="row">
                                    <div class="col">
                                        <h5>Configurações da atualização:</h5>
                                        <label class="form-check spacer-top">
                                            <input class="form-check-input" type="checkbox" v-model="applyDefaultValuesOnNewFields
                                    " id="applyDefaultValuesOnNewFieldsCheckbox" />
                                            Aplicar valores padrões para novos
                                            campos em registros existentes
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col">
                                        <span class="text-muted">Atualmente a tabela possui
                                            {{ hasEntities }} registros.</span>
                                        <span class="text-orange" v-show="hasEntities">
                                            <br />
                                            Atenção: ao alterar a estrutura da
                                            tabela você irá afetar os dados dos
                                            registros existentes! Recomendamos
                                            que você efetue uma cópia da tabela
                                            antes de efetuar alterações.
                                        </span>
                                    </div>
                                    <div class="col-auto align-right">
                                        <button class="btn btn-primary" @click="saveTable" id="saveTableButton">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar tabela
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable"
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "DbTableEditPage",
    components: {
        draggable
    },
    data() {
        return {
            table: {
                name: "",
                fields: []
            },
            applyDefaultValuesOnNewFields: false,
            hasEntities: 0,
            validationErrors: []
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true
        store.backUrl = "/database/tables"

        // new
        if (!this.$route.params.table_id) {
            this.table = {
                name: "Nova tabela",
                fields: []
            }

            this.addColumn()
            return
        }

        var response = await Api.database.tables.get(
            this.$route.params.table_id
        )
        this.table = response.data

        var hasEntitiesResponse = await Api.database.entities.list(1, 1, {
            table: this.table._id
        })
        this.hasEntities = hasEntitiesResponse.data.total
    },
    methods: {
        addColumn() {
            this.table.fields.push({
                name: "",
                label: "",
                type: "string",
                length: "",
                is_unique: false,
                is_required: false,
                position: 0
            })

            // set focus on the first input of new line
            setTimeout(() => {
                var inputs = document.querySelectorAll('input[name="name"]')
                inputs[inputs.length - 1].focus()
            }, 50)
        },
        removeColumn(index) {
            this.table.fields.splice(index, 1)
        },
        duplicateColumn(index) {
            var field = this.table.fields[index]
            this.table.fields.push({
                name: field.name,
                label: field.label,
                type: field.type,
                length: field.length,
                is_unique: field.is_unique,
                is_required: field.is_required,
                position: field.position
            })
        },

        importTableStructure() {
            if (this.table._id) {
                if (
                    !confirm(
                        "Ao importar você substituirá a estrutura atual. Deseja continuar?"
                    )
                ) {
                    return
                }
            }

            var input = document.createElement("input")
            input.type = "file"
            input.accept = ".json"

            input.onchange = async (e) => {
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onload = async (e) => {
                    // TODO: validar se o arquivo é uma tabela válida (JSON Schema)

                    var data = JSON.parse(e.target.result)
                    var currentId = this.table._id

                    // remove fields.$._id
                    data.fields.forEach((field) => {
                        if (field._id) delete field._id
                    })

                    this.table = data
                    this.table._id = currentId
                }
                reader.readAsText(file)
            }
            input.click()
        },

        async saveTable() {
            // TODO: validate data
            var errors = this.validateColumns()
            this.validationErrors = errors

            if (errors.length) {
                return
            }

            try {
                // new
                if (!this.$route.params.table_id) {
                    await Api.database.tables.create(this.table)
                } else {
                    // update
                    this.table.apply_default_values_new_fields =
                        this.applyDefaultValuesOnNewFields
                    await Api.database.tables.update(this.table._id, this.table)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "A tabela foi salva com sucesso!"
                })

                this.$router.push("/database/tables")
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                if (message.indexOf("name_1 dup key")) {
                    message = "Já existe uma tabela com esse nome."
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar a tabela: " + message
                })
            }
        },

        validateColumns() {
            var errors = []

            this.table.fields.forEach((field, index) => {
                if (!field.name) {
                    errors.push(
                        `O campo "Nome" da campo ${index + 1} é obrigatório.`
                    )
                }

                if (!field.label) {
                    errors.push(
                        `O campo "Label" da campo "${field.name}" é obrigatório.`
                    )
                }

                if (!field.type) {
                    errors.push(
                        `O campo "Tipo" da campo "${field.name}" é obrigatório.`
                    )
                }

                if (field.type === "string" && !field.length) {
                    errors.push(
                        `O campo "Tamanho" da campo "${field.name}" é obrigatório.`
                    )
                }

                if (field.type === "longtext" && !field.length) {
                    errors.push(
                        `O campo "Tamanho" da campo "${field.name}" é obrigatório.`
                    )
                }
            })

            // duplicated names
            var names = this.table.fields.map((field) => field.name)
            var duplicatedNames = names.filter(
                (name, index) => names.indexOf(name) !== index
            )
            duplicatedNames = [...new Set(duplicatedNames)]
            if (duplicatedNames.length) {
                errors.push(
                    `Existem campos com nomes duplicados: ${duplicatedNames.join(
                        ", "
                    )}`
                )
            }

            // no fields
            if (!this.table.fields.length) {
                errors.push("A tabela deve conter pelo menos um campo.")
            }

            return errors
        },
        updateFieldsPosition() {
            // update table fields position based on their index on array
            this.table.fields.forEach((field, index) => {
                field.position = index
            })
        },
    }
}
</script>
