<template>
    <div>
        <div class="page-wrapper" v-if="queue._id">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-list-check"></i>
                                        Visualizar fila: {{ queue.name }}
                                    </h3>
                                    <p class="text-muted">
                                        Gestão de filas internas do workspace
                                        com Redis
                                    </p>
                                </div>
                                <div class="col-auto">
                                    <button
                                        class="btn"
                                        @click="refresh()"
                                        id="refreshDataButton"
                                    >
                                        <i class="ti ti-refresh"></i>
                                        Atualizar dados
                                    </button>
                                    <span>&nbsp;</span>
                                    <button
                                        class="btn"
                                        v-if="queue.status == 'paused'"
                                        @click="resume()"
                                        id="startConsumptionButton"
                                    >
                                        <i class="ti ti-player-play-filled"></i>
                                        Iniciar consumo
                                    </button>
                                    <button
                                        class="btn"
                                        v-if="queue.status == 'running'"
                                        @click="pause()"
                                        id="stopConsumptionButton"
                                    >
                                        <i
                                            class="ti ti-player-pause-filled"
                                        ></i>
                                        Pausar consumo
                                    </button>
                                    <span>&nbsp;</span>
                                    <button
                                        class="btn"
                                        @click="clean()"
                                        id="clearQueueButton"
                                    >
                                        <i class="ti ti-eraser"></i>
                                        Limpar fila
                                    </button>
                                    <span>&nbsp;</span>
                                    <a
                                        :href="'/queues/edit/' + queue._id"
                                        class="btn"
                                        id="editQueueButton"
                                    >
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>Informações da fila</h4>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        <span
                                                            class="badge bg-green text-green-fg"
                                                            v-if="
                                                                queue.status ==
                                                                'running'
                                                            "
                                                            >{{
                                                                queue.status
                                                            }}</span
                                                        >
                                                        <span
                                                            class="badge bg-azure text-azure-fg"
                                                            v-if="
                                                                queue.status ==
                                                                'paused'
                                                            "
                                                            >{{
                                                                queue.status
                                                            }}</span
                                                        >
                                                        <span
                                                            class="badge bg-azure text-azure-fg"
                                                            v-if="
                                                                queue.status ==
                                                                'rate_limit_waiting'
                                                            "
                                                            >{{
                                                                queue.status
                                                            }}</span
                                                        >
                                                        <span
                                                            class="badge"
                                                            v-if="
                                                                queue.status ==
                                                                'waiting'
                                                            "
                                                            >{{
                                                                queue.status
                                                            }}</span
                                                        >
                                                    </td>
                                                </tr>
                                                <tr v-if="queue.flow_id">
                                                    <td>Consumer ativo</td>
                                                    <td>
                                                        <a
                                                            :href="
                                                                '/flows/' +
                                                                queue.flow_id
                                                                    ._id
                                                            "
                                                            target="_blank"
                                                        >
                                                            {{
                                                                queue.flow_id
                                                                    .name
                                                            }}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr v-if="queue.flow_id">
                                                    <td>
                                                        Limite de execuções por
                                                        segundo
                                                    </td>
                                                    <td>
                                                        {{
                                                            queue.max_per_second
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-6">
                                        <h4>Status de jobs</h4>
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Delayed</td>
                                                    <td>
                                                        {{
                                                            queue.queue_status
                                                                .delayed
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Pendentes de execução
                                                        <span class="text-muted"
                                                            >(Incluindo
                                                            Delayed)</span
                                                        >
                                                    </td>
                                                    <td>
                                                        {{
                                                            queue.queue_status
                                                                .waiting +
                                                            queue.queue_status
                                                                .paused +
                                                            queue.queue_status
                                                                .delayed
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Em execução
                                                    </td>
                                                    <td>
                                                        {{queue.queue_status.active}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Falhados</td>
                                                    <td>
                                                        {{
                                                            queue.queue_status
                                                                .failed
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Completados</td>
                                                    <td>
                                                        {{
                                                            queue.queue_status
                                                                .completed
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h3>Jobs pendentes</h3>
                                    </div>
                                    <div class="col-auto">
                                        <button
                                            class="btn btn-primary"
                                            @click="addNewMessage(queue)"
                                            id="addNewMessageButton"
                                        >
                                            <i class="ti ti-plus"></i>
                                            Adicionar mensagem
                                        </button>
                                    </div>
                                </div>

                                <table
                                    class="table mt-2"
                                    v-if="jobs && jobs.length"
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th width="30%">
                                                Trecho da mensagem (100
                                                caracteres)
                                            </th>
                                            <th>Publicado por</th>
                                            <th>Publicado em</th>
                                            <th>Delayed Message</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="job in jobs" :key="job.id">
                                            <td>
                                                {{ job.id }}
                                            </td>
                                            <td>
                                                <pre
                                                    class="message-preview"
                                                    @click="viewMessage(job)"
                                                    >{{
                                                        job.data.message
                                                            .length > 100
                                                            ? job.data.message.substr(
                                                                  0,
                                                                  100
                                                              ) + "[...]"
                                                            : job.data.message
                                                    }}</pre
                                                >
                                            </td>
                                            <td v-if="job.data.flow_id">
                                                <a
                                                    :href="
                                                        '/flows/' +
                                                        job.data.flow_id
                                                    "
                                                    target="_blank"
                                                >
                                                    {{ job.data.flow_name }}
                                                </a>
                                                <br />
                                                <small
                                                    >ID:
                                                    {{
                                                        job.data.flow_id
                                                    }}</small
                                                >
                                            </td>
                                            <td v-if="!job.data.flow_id">
                                                <small class="text-muted"
                                                    >Inserção manual</small
                                                >
                                            </td>
                                            <td>
                                                {{ toDate(job.timestamp) }}
                                            </td>
                                            <td>
                                                <span
                                                    class="badge bg-blue-lt"
                                                    v-if="!job.opts.delay"
                                                    >Não</span
                                                >
                                                <span
                                                    class="badge bg-red-lt"
                                                    v-if="
                                                        job.opts.delay &&
                                                        !job.delay
                                                    "
                                                    >Delayed: Execução
                                                    imediata</span
                                                >
                                                <span
                                                    class="badge bg-orange-lt"
                                                    v-if="
                                                        job.opts.delay &&
                                                        job.delay
                                                    "
                                                    >Delayed:
                                                    <b>Aguardando</b></span
                                                >
                                            </td>
                                            <td>
                                                <button
                                                    class="btn btn-sm btn-danger removeJobButton"
                                                    @click="remove(job)"
                                                >
                                                    <i class="ti ti-trash"></i>
                                                    Remover
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p
                                    class="text-muted"
                                    v-if="!jobs || !jobs.length"
                                >
                                    Nenhum job pendente para visualização
                                </p>
                            </div>
                            <div class="card-footer">
                                <Pagination
                                    :key="paginationKey"
                                    :showPerPage="showPerPage"
                                    :currentPage="currentPage"
                                    :total="jobsTotal"
                                    v-if="jobsTotal"
                                    @pageChange="updatePage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QueueMessageViewer
            :job="selectedJob"
            :key="showQueueMessageKey"
            v-if="showQueueMessage"
        />
        <QueueMessageAdd
            :queue="queue"
            :key="showQueueMessageAddKey"
            v-if="showQueueMessageAdd"
            @added="refresh()"
        />
    </div>
</template>

<style scoped>
pre.message-preview {
    cursor: pointer;
}
pre.message-preview:hover {
    border: 1px solid #4299e1;
    background: #f7fafc;
}
</style>

<script>
import { store } from "../store"
import Api from "../services/api"
import moment from "moment"
import Pagination from "@/components/Pagination.vue"
import QueueMessageViewer from "@/components/QueueMessageViewer.vue"
import QueueMessageAdd from "@/components/QueueMessageAdd.vue"
import EventBus from "@/services/event-bus"

export default {
    name: "QueueViewPage",
    components: {
        Pagination,
        QueueMessageViewer,
        QueueMessageAdd
    },
    data() {
        return {
            queue: {},
            jobs: [],
            jobsTotal: 0,
            currentPage: 1,
            showPerPage: 10,
            paginationKey: null,
            showQueueMessage: false,
            showQueueMessageKey: null,
            selectedJob: {},
            showQueueMessageAddKey: null,
            showQueueMessageAdd: false
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true

        this.loadQueue()
        this.loadJobs()
    },
    methods: {
        async loadQueue() {
            try {
                const queueId = this.$route.params.id
                const response = await Api.queues.get(queueId)
                this.queue = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async loadJobs() {
            try {
                const queueId = this.$route.params.id
                const response = await Api.queues.listJobs(
                    queueId,
                    this.currentPage,
                    this.showPerPage,
                    ["paused", "waiting", "delayed"]
                )

                this.jobs = response.data.items
                this.jobsTotal = response.data.total
                this.paginationKey = Math.random()
            } catch (error) {
                console.log(error)
            }
        },
        async refresh() {
            await this.loadQueue()
            await this.loadJobs()
        },
        async pause() {
            try {
                const queueId = this.$route.params.id
                await Api.queues.pause(queueId)
                await this.loadQueue()
            } catch (error) {
                console.log(error)
            }
        },
        async resume() {
            try {
                const queueId = this.$route.params.id
                await Api.queues.resume(queueId)
                await this.loadQueue()
            } catch (error) {
                console.log(error)
            }
        },
        async clean() {
            if (
                !confirm(
                    "Tem certeza que deseja limpar a fila? Esta ação não pode ser desfeita."
                )
            ) {
                return
            }

            try {
                const queueId = this.$route.params.id
                await Api.queues.clean(queueId)
                await this.loadQueue()
            } catch (error) {
                console.log(error)
            }
        },
        toDate(timestamp) {
            return moment(timestamp).format("DD/MM/YYYY HH:mm:ss")
        },
        updatePage(page) {
            this.currentPage = page
            console.log("updatePage", page)
            this.loadJobs()
        },
        viewMessage(job) {
            this.showQueueMessage = true
            this.showQueueMessageKey = Math.random()
            this.selectedJob = job
        },
        addNewMessage(queue) {
            this.showQueueMessageAdd = true
            this.showQueueMessageAddKey = Math.random()
        },
        async remove(job) {
            if (
                !confirm(
                    "Tem certeza que deseja remover este job? Esta ação não pode ser desfeita."
                )
            ) {
                return
            }

            try {
                const queueId = this.$route.params.id
                await Api.queues.removeJob(queueId, job.id)

                this.refresh()

                EventBus.emit("message", {
                    type: "success",
                    message: "Mensagem removida"
                })
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Erro ao remover mensagem: " + error.message
                })
            }
        }
    }
}
</script>
