<template>
    <div
        class="collapse navbar-collapse"
        id="navbar-menu"
        v-if="store.workspace"
    >
        <div class="row">
            <div class="col mt-3">
                <HeaderLogo />
            </div>
        </div>
        <ul class="navbar-nav pt-lg-3">
            <li class="nav-item">
                <a
                    class="nav-link text-yellow"
                    href="#"
                    @click="goToAccountPanel()"
                    id="homeNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-arrow-left"></i>
                    </span>
                    <span class="nav-link-title">Voltar ao início</span>
                </a>
            </li>
            <li class="nav-item">
                <div class="nav-link strong">
                    {{ store.workspace.name }}
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/flows" id="flowsNavbarButton">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-box"></i>
                    </span>
                    <span class="nav-link-title">Flows</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="/database/tables"
                    id="tablesNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-database"></i>
                    </span>
                    <span class="nav-link-title">
                        Database
                        <sup class="text-azure">beta</sup>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="/dashboards"
                    id="dashboardsNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-chart-bar"></i>
                    </span>
                    <span class="nav-link-title">Dashboards</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/forms" id="formsNavbarButton">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-forms"></i>
                    </span>
                    <span class="nav-link-title">
                        Forms
                        <sup class="text-azure">beta</sup>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="/queues" id="queuesNavbarButton">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-list-check"></i>
                    </span>
                    <span class="nav-link-title"> Filas internas </span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="/variables"
                    id="variablesNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-code"></i>
                    </span>
                    <span class="nav-link-title">Variáveis</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="/service-accounts"
                    id="serviceAccountNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-key"></i>
                    </span>
                    <span class="nav-link-title">Contas de serviços</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="/custom-nodes"
                    id="customNodesNavbarButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-box"></i>
                    </span>
                    <span class="nav-link-title">
                        Conectores personalizados
                        <sup class="text-azure">beta</sup>
                    </span>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a
                    class="nav-link dropdown-toggle"
                    href="#monitoring"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    role="button"
                    aria-expanded="false"
                    id="monitoringNavbarDropdownButton"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-device-desktop-analytics"></i>
                    </span>
                    <span class="nav-link-title"> Monitoramento </span>
                </a>
                <div class="dropdown-menu" data-bs-popper="static">
                    <a
                        class="dropdown-item"
                        href="/monitoring/active"
                        id="monitoringNavbarActiveButton"
                    >
                        Execução em tempo real
                    </a>
                    <a
                        class="dropdown-item"
                        href="/monitoring/completed"
                        id="monitoringNavbarCompletedButton"
                    >
                        Flows executados
                    </a>
                    <a
                        class="dropdown-item"
                        href="/monitoring"
                        id="monitoringNavbarLogsButton"
                    >
                        Logs de monitoramento
                    </a>
                    <a class="dropdown-item" href="/alert-rules">
                        Regras de alerta
                    </a>
                </div>
            </li>
        </ul>
        <div class="m-2">{{ branch }}</div>
    </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo.vue"
import { store } from "../store"
import Api from "../services/api"

export default {
    name: "SidebarMenu",
    components: {
        HeaderLogo
    },
    data() {
        return {
            store: store,
            branch: ""
        }
    },
    mounted() {
        this.getCurrentBranch()
    },
    methods: {
        goToAccountPanel() {
            window.location.href = store.accountPanelUrl
        },
        async getCurrentBranch() {
            let response
            try {
                response = await Api.workspaces.getCurrentBranchWorkspace()
                this.branch = response?.data?.branchName
                    ? response.data.branchName
                    : ""
            } catch (err) {
                console.log(err)
            }
            return response
        }
    }
}
</script>
