<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <QueuesList />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QueuesList from "../components/QueuesList"
import { store } from "../store"

export default {
    name: "QueuesPage",
    components: {
        QueuesList
    },
    data() {
        return {
            queues: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
    }
}
</script>
