import axios from "axios"
import EventBus from "./event-bus"

const accountApiAxiosInstance = axios.create()

accountApiAxiosInstance.defaults.baseURL = process.env.VUE_APP_ACCOUNT_API_URL
accountApiAxiosInstance.interceptors.request.use((request) => {
    if (localStorage.getItem("token")) {
        request.headers["Authorization"] =
            "Bearer " + localStorage.getItem("token")
    }
    return request
})

// redirect to login page when receiving 403 response
accountApiAxiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response.status === 403) {
            EventBus.emit("call-logout")
        }

        EventBus.emit("ajax-request-end")
        return Promise.reject(error)
    }
)

// call event bus when ajax start
accountApiAxiosInstance.interceptors.request.use((request) => {
    if (!accountApiAxiosInstance._disable_loaders) {
        EventBus.emit("ajax-request-start")
    }
    return request
})

// call event bus when ajax end
accountApiAxiosInstance.interceptors.response.use((response) => {
    accountApiAxiosInstance._disable_loaders = false
    EventBus.emit("ajax-request-end")
    return response
})

export default {
    refreshToken() {
        return accountApiAxiosInstance.put("v1/authenticate/refresh")
    },

    /**
     * Flow templates
     */
    flowTemplates: {
        /**
         * Get all flow templates
         * @returns {Promise<Object>}
         */
        list: (currentPage = 1, showPerPage = 20, filter = {}) => {
            var params = {
                page: currentPage,
                limit: showPerPage,
                query: filter.query ?? null,
                tag: filter.tag ?? null
            }

            return accountApiAxiosInstance.get("v1/flow-templates", { params })
        },

        /**
         * List all tags to filter
         * @returns {Promise<Object>}
         */
        getTags() {
            return accountApiAxiosInstance.get("v1/flow-templates/tags")
        },

        /**
         * Get workspace by id
         */
        get: (id) => {
            return accountApiAxiosInstance.get("v1/flow-template/" + id)
        }
    },

    /**
     * Service accounts
     */
    serviceAccounts: {
        /**
         * Service account OAuth2 operations
         */
        oauth2: {
            getAuthorizationUrl: (
                auth_type,
                service_account_id,
                workspace_id
            ) => {
                return accountApiAxiosInstance.post(
                    "v1/service-accounts/oauth2/authorize/" + auth_type,
                    {
                        service_account_id: service_account_id,
                        workspace_id: workspace_id
                    }
                )
            }
        }
    }
}
