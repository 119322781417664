<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="row g-2">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="col">
                                <h3 class="card-title">
                                    <i class="ti ti-database"></i>
                                    {{
                                        isNew
                                            ? "Novo registro"
                                            : "Editar registro"
                                    }}
                                    da tabela "{{ table.name }}"
                                </h3>
                            </div>
                            <div class="col-auto">
                                <small class="text-muted" v-if="!isNew"
                                    >ID: {{ entity._id }}</small
                                >
                            </div>
                        </div>

                        <div class="card-body">
                            <div
                                class="row mb-3"
                                v-for="field in table.fields"
                                :key="field.name"
                            >
                                <div class="col">
                                    <label
                                        class="form-label"
                                        v-if="field.type !== 'boolean'"
                                        :class="
                                            field.is_required ? 'required' : ''
                                        "
                                        >{{ field.label }}</label
                                    >

                                    <label
                                        class="form-check spacer-top"
                                        v-if="field.type === 'boolean'"
                                    >
                                        <input
                                            class="form-check-input entityDataCheckbox"
                                            type="checkbox"
                                            v-model="entity.data[field.name]"
                                        />
                                        {{ field.label }}
                                    </label>

                                    <input
                                        type="text"
                                        class="form-control entityDataInput"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'string'"
                                    />

                                    <input
                                        type="datetime-local entityDataInput"
                                        class="form-control"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'datetime'"
                                    />

                                    <input
                                        type="date entityDataInput"
                                        class="form-control"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'date'"
                                    />

                                    <textarea
                                        class="form-control entityDataInput"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'longtext'"
                                        rows="5"
                                    >
                                    </textarea>

                                    <input
                                        type="text"
                                        class="form-control entityDataInput"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'integer'"
                                    />

                                    <input
                                        type="text"
                                        class="form-control entityDataInput"
                                        v-model="entity.data[field.name]"
                                        :placeholder="field.type"
                                        v-if="field.type === 'float'"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <button
                                class="btn btn-primary"
                                @click="saveEntity()"
                                id="saveEntityButton"
                            >
                                <i class="ti ti-device-floppy"></i>
                                Salvar registro
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"

export default {
    name: "DbEntityEditPage",
    data() {
        return {
            isNew: false,
            table: {},
            entity: {
                data: {}
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showBackButton = true

        if (this.$route.params.entity_id) {
            var entityResponse = await Api.database.entities.get(
                this.$route.params.entity_id
            )
            this.entity = entityResponse.data

            var tableResponse = await Api.database.tables.get(this.entity.table)
            this.table = tableResponse.data
        } else {
            this.isNew = true
            var tableResponse = await Api.database.tables.get(
                this.$route.params.table_id
            )
            this.table = tableResponse.data
        }

        store.backUrl = "/database/entities/" + this.table._id
    },
    methods: {
        async saveEntity() {
            try {
                // TODO: Validate data

                var response
                if (this.isNew) {
                    this.entity.table = this.table._id
                    response = await Api.database.entities.create(this.entity)
                } else {
                    response = await Api.database.entities.update(
                        this.entity._id,
                        this.entity
                    )
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Registro salvo com sucesso!"
                })

                this.$router.push("/database/entities/" + this.table._id)
            } catch (error) {
                var message = error.message

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    message = error.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o registro: " + message
                })
            }
        }
    }
}
</script>
