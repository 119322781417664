<template>
    <div class="grid-stack-item">
        <div class="grid-stack-item-content">
            <TextHtmlWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                v-if="widget.type == 'text_html'"
            />
            <ExecutionCountWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                v-if="widget.type == 'execution_count'"
            />
            <ExecutionHistogramWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                v-if="widget.type == 'execution_histogram'"
            />
            <LogCountWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                v-if="widget.type == 'log_count'"
            />
            <LogListWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                @refreshWidgetData="refreshWidgetData"
                v-if="widget.type == 'log_list'"
            />
            <LogHistogramWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                v-if="widget.type == 'log_histogram'"
            />
            <ExecutionTimeWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :flows="flows"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                v-if="widget.type == 'execution_time'"
            />
            <DatabaseListWidget
                :editMode="editMode"
                :widget="widget"
                :widgetsQueryResult="widgetsQueryResult"
                :tables="tables"
                @updateConfig="updateConfig"
                @removeWidget="removeWidget"
                @duplicateWidget="duplicateWidget"
                @refreshData="refreshData"
                @refreshWidgetData="refreshWidgetData"
                v-if="widget.type == 'database_list'"
            />
            <div class="widget-type" v-if="editMode">
                {{ widget.type }}
            </div>
        </div>
    </div>
</template>
<style>
.btn-icon {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 5px;
}

.grid-stack-item-content {
    position: relative;
}

.grid-stack-item-content .widget-options {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    opacity: 0;
}

.grid-stack-item-content:hover {
    background: #f8f8f8;
}

.view-mode .grid-stack-item-content:hover {
    background: #fff;
}

.grid-stack-item-content:hover .widget-options {
    opacity: 1;
}

.widget-type {
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    color: #fff;
    opacity: 0.5;
    padding: 2px 5px;
    font-size: 0.9em;
    border-radius: 3px;
    display: none;
}
.grid-stack-item-content:hover .widget-type {
    display: block;
}
</style>
<script>
import TextHtmlWidget from "./TextHtmlWidget.vue"
import ExecutionCountWidget from "./ExecutionCountWidget.vue"
import ExecutionHistogramWidget from "./ExecutionHistogramWidget.vue"
import ExecutionTimeWidget from "./ExecutionTimeWidget.vue"
import LogCountWidget from "./LogCountWidget.vue"
import LogListWidget from "./LogListWidget.vue"
import DatabaseListWidget from "./DatabaseListWidget.vue"
import LogHistogramWidget from "./LogHistogramWidget.vue"

export default {
    name: "Widget",
    components: {
        TextHtmlWidget,
        ExecutionCountWidget,
        ExecutionHistogramWidget,
        ExecutionTimeWidget,
        LogCountWidget,
        LogListWidget,
        DatabaseListWidget,
        LogHistogramWidget
    },
    props: {
        widget: Object,
        editMode: Boolean,
        flows: Array,
        tables: Array,
        widgetsQueryResult: Object
    },
    data() {
        return {
            config: {}
        }
    },
    async mounted() {
        if (this.widget.config) this.config = this.widget.config

        this.$watch(
            "config",
            () => {
                this.configJson = JSON.stringify(this.config)
                this.$emit("updateConfig", this.widget, this.config)
            },
            { deep: true }
        )
    },

    methods: {
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        duplicateWidget() {
            this.$emit("duplicateWidget", this.widget)
        },
        updateConfig(widget, config) {
            this.config = config
        },
        refreshData() {
            this.$emit("refreshData")
        },
        refreshWidgetData(widget, config = {}) {
            this.$emit("refreshWidgetData", widget, config)
        }
    }
}
</script>
