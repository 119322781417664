<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="row g-2">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="col">
                                <h3 class="card-title">
                                    <i class="ti ti-box"></i>
                                    {{
                                        isNew
                                            ? "Novo conector"
                                            : "Editar conector"
                                    }}
                                </h3>
                            </div>
                            <div class="col-auto">
                                <small v-if="!isNew" class="text-muted"
                                    >ID: {{ customNode._id }}</small
                                >
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <ul
                                    class="nav nav-tabs card-header-tabs"
                                    data-bs-toggle="tabs"
                                    role="tablist"
                                >
                                    <li class="nav-item" role="presentation">
                                        <a
                                            href="#tabs-config"
                                            class="nav-link active"
                                            data-bs-toggle="tab"
                                            aria-selected="true"
                                            role="tab"
                                            tabindex="-1"
                                        >
                                            <i class="ti ti-settings"></i>
                                            Configurações do conector
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a
                                            href="#tabs-execution"
                                            class="nav-link"
                                            data-bs-toggle="tab"
                                            aria-selected="false"
                                            role="tab"
                                        >
                                            <i class="ti ti-code"></i>
                                            Funções do conector
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div
                                        id="tabs-config"
                                        class="tab-pane active show"
                                        role="tabpanel"
                                    >
                                        <div class="row">
                                            <div class="col">
                                                <div class="row mb-3">
                                                    <div class="col-3">
                                                        <div class="card">
                                                            <div
                                                                class="card-body"
                                                            >
                                                                <label
                                                                    class="form-label"
                                                                    >Imagem do
                                                                    conector</label
                                                                >
                                                                <span
                                                                    class="avatar avatar-xl rounded mb-3"
                                                                >
                                                                    <img
                                                                        v-if="
                                                                            image_preview
                                                                        "
                                                                        :src="
                                                                            image_preview
                                                                        "
                                                                        alt="Preview da imagem"
                                                                    />
                                                                </span>
                                                                <input
                                                                    id="updateImageInput"
                                                                    type="file"
                                                                    class="form-control"
                                                                    @change="
                                                                        updateImage
                                                                    "
                                                                />
                                                                <small
                                                                    class="form-hint"
                                                                    >Usar
                                                                    128x128px
                                                                    com no
                                                                    máximo de
                                                                    100Kb</small
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="row mb-2">
                                                            <div class="col">
                                                                <label
                                                                    class="form-check"
                                                                >
                                                                    <input
                                                                        id="customNodeEnabledCheckbox"
                                                                        v-model="
                                                                            customNode.enabled
                                                                        "
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                    />
                                                                    <span
                                                                        class="form-check-label"
                                                                    >
                                                                        Habilitado</span
                                                                    >
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div
                                                                class="col-6 mb-3"
                                                            >
                                                                <label
                                                                    class="form-label required"
                                                                    >Nome do
                                                                    conector</label
                                                                >
                                                                <input
                                                                    id="customNodeNameInput"
                                                                    v-model="
                                                                        customNode.name
                                                                    "
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Exemplo: Inserir pedido no ERP"
                                                                />
                                                                <div
                                                                    class="form-hint"
                                                                >
                                                                    O nome que
                                                                    deverá
                                                                    identificar
                                                                    o seu
                                                                    conector
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-6 mb-3"
                                                            >
                                                                <label
                                                                    class="form-label"
                                                                    >Nome do
                                                                    serviço</label
                                                                >
                                                                <input
                                                                    id="customNodeServiceNameInput"
                                                                    v-model="
                                                                        customNode.service_name
                                                                    "
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Exemplo: ERP Interno"
                                                                />
                                                                <div
                                                                    class="form-hint"
                                                                >
                                                                    Utilize um
                                                                    nome de
                                                                    serviço que
                                                                    facilite a
                                                                    sua
                                                                    identificação
                                                                    nos fluxos
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div
                                                                class="col mb-3"
                                                            >
                                                                <label
                                                                    class="form-label required"
                                                                    >Descrição</label
                                                                >
                                                                <input
                                                                    id="customNodeDescriptionInput"
                                                                    v-model="
                                                                        customNode.description
                                                                    "
                                                                    type="text"
                                                                    class="form-control"
                                                                    placeholder="Exemplo: Efetua a inserção do pedido na base de dados do ERP"
                                                                />
                                                                <div
                                                                    class="form-hint"
                                                                >
                                                                    Descrição
                                                                    que será
                                                                    exibida aos
                                                                    usuários no
                                                                    editor de
                                                                    fluxo
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label class="form-label"
                                                    >Parâmetros de
                                                    configuração</label
                                                >
                                                <p class="text-muted">
                                                    Aqui você deve inserir os
                                                    parâmetros que o seu
                                                    conector poderá receber de
                                                    configuração de entrada
                                                    durante a edição de um
                                                    fluxo.
                                                </p>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Código do campo
                                                            </th>
                                                            <th>Label</th>
                                                            <th>Tipo</th>
                                                            <th>
                                                                Valor padrão
                                                            </th>
                                                            <th>Obrigatório</th>
                                                            <th>Posição</th>
                                                            <th
                                                                class="text-right"
                                                            >
                                                                Ações
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(
                                                                field, index
                                                            ) in customNode.params"
                                                            :key="index"
                                                        >
                                                            <td>
                                                                <input
                                                                    v-model="
                                                                        field.name
                                                                    "
                                                                    type="text"
                                                                    name="name"
                                                                    class="form-control form-control-sm fieldNameInput"
                                                                    placeholder="field"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    v-model="
                                                                        field.label
                                                                    "
                                                                    type="text"
                                                                    class="form-control form-control-sm fieldLabelInput"
                                                                    placeholder="Label do campo"
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    v-model="
                                                                        field.type
                                                                    "
                                                                    class="form-select form-select-sm fieldTypeSelect"
                                                                >
                                                                    <option
                                                                        value="text"
                                                                    >
                                                                        Texto
                                                                    </option>
                                                                    <option
                                                                        value="textarea"
                                                                    >
                                                                        Texto
                                                                        longo
                                                                    </option>
                                                                    <option
                                                                        value="select"
                                                                    >
                                                                        Dropdown
                                                                    </option>
                                                                    <option
                                                                        value="code-editor"
                                                                    >
                                                                        Editor
                                                                        de
                                                                        código
                                                                    </option>
                                                                    <option
                                                                        value="table-params"
                                                                    >
                                                                        Tabela
                                                                        de
                                                                        parâmetros
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input
                                                                    v-model="
                                                                        field.default_value
                                                                    "
                                                                    type="text"
                                                                    class="form-control form-control-sm fieldDefaultValueInput"
                                                                    placeholder=""
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    v-model="
                                                                        field.is_required
                                                                    "
                                                                    type="checkbox"
                                                                    class="form-check-input fieldIsRequiredCheckbox"
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    v-model="
                                                                        field.position
                                                                    "
                                                                    type="text"
                                                                    class="form-control form-control-sm fieldPositionInput"
                                                                    placeholder="Posição"
                                                                />
                                                            </td>
                                                            <td
                                                                class="text-right"
                                                            >
                                                                <button
                                                                    v-show="
                                                                        field.type ==
                                                                        'select'
                                                                    "
                                                                    class="btn btn-sm editOptionsButton"
                                                                    @click="
                                                                        editOptions(
                                                                            field
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="ti ti-list"
                                                                    ></i>
                                                                    Opções
                                                                </button>
                                                                <button
                                                                    v-show="
                                                                        field.type ==
                                                                        'table-params'
                                                                    "
                                                                    class="btn btn-sm editColumnsButton"
                                                                    @click="
                                                                        editTableParamColumns(
                                                                            field
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="ti ti-columns-3"
                                                                    ></i>
                                                                    Colunas
                                                                </button>
                                                                <span
                                                                    v-show="
                                                                        field.type ==
                                                                        'select'
                                                                    "
                                                                    >&nbsp;</span
                                                                >
                                                                <button
                                                                    class="btn btn-sm duplicateFieldButton"
                                                                    @click="
                                                                        duplicateField(
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="ti ti-copy"
                                                                    ></i>
                                                                    Duplicar
                                                                </button>
                                                                <span
                                                                    >&nbsp;</span
                                                                >
                                                                <button
                                                                    class="btn btn-sm btn-ghost-red removeFieldButton"
                                                                    @click="
                                                                        removeField(
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="ti ti-trash"
                                                                    ></i>
                                                                    Remover
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <button
                                                    id="addFieldButton"
                                                    class="btn text-blue"
                                                    @click="addField()"
                                                >
                                                    <i class="ti ti-plus"></i>
                                                    Adicionar parâmetro
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="tabs-execution"
                                        class="tab-pane"
                                        role="tabpanel"
                                    >
                                        <div class="col-4 mb-3">
                                            <label class="form-label"
                                                >Tipo de execução
                                                <em>*</em></label
                                            >
                                            <select
                                                id="executionTypeSelect"
                                                v-model="
                                                    customNode.execution_type
                                                "
                                                class="form-select"
                                            >
                                                <option value="custom_code">
                                                    Executa código customizado
                                                </option>
                                                <!-- <option value="http_request">Request HTTP</option> -->
                                            </select>
                                        </div>

                                        <div
                                            id="monacoEditorContainer"
                                            style="height: 500px"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <div class="row">
                                <div class="col">
                                    <button
                                        id="saveCustomNodeButton"
                                        class="btn btn-primary"
                                        @click="saveCustomNode()"
                                    >
                                        <i class="ti ti-device-floppy"></i>
                                        Salvar
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button
                                        id="importCustomNodeButton"
                                        class="btn"
                                        @click="importCustomNode()"
                                    >
                                        <i class="ti ti-upload"></i>
                                        Importar conector
                                    </button>
                                    <span>&nbsp;</span>
                                    <button
                                        id="exportCustomNodeButton"
                                        class="btn"
                                        @click="exportCustomNode()"
                                    >
                                        <i class="ti ti-download"></i>
                                        Exportar este conector
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="modal-edit-options"
            class="modal modal-blur fade"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar opções</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body pt-2">
                        <div class="modal-body-container">
                            <table class="table table-hovered">
                                <thead>
                                    <tr>
                                        <th>Label</th>
                                        <th>Valor</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            option, index
                                        ) in currentFieldOptions"
                                        :key="option"
                                    >
                                        <td>
                                            <input
                                                v-model="option.label"
                                                class="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                v-model="option.value"
                                                class="form-control"
                                            />
                                        </td>
                                        <td>
                                            <button
                                                class="btn btn-sm btn-ghost-red"
                                                @click="
                                                    removeFieldOption(index)
                                                "
                                            >
                                                <i class="ti ti-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button
                                class="btn btn-sm"
                                @click="addFieldOption()"
                            >
                                <i class="ti ti-plus"></i>
                                Adicionar opção
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="modal-edit-table-param-columns"
            class="modal modal-blur fade"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar colunas</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body pt-2">
                        <div class="modal-body-container">
                            <table class="table table-hovered">
                                <thead>
                                    <tr>
                                        <th>Código do campo</th>
                                        <th>Label</th>
                                        <th>Tipo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(
                                            column, index
                                        ) in currentFieldTableParamColumns"
                                        :key="column"
                                    >
                                        <td>
                                            <input
                                                v-model="column.key"
                                                class="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                v-model="column.label"
                                                class="form-control"
                                            />
                                        </td>
                                        <td>
                                            <select
                                                v-model="column.type"
                                                class="form-select"
                                            >
                                                <option value="text">
                                                    Texto
                                                </option>
                                                <!-- <option value="select">Dropdown</option> -->
                                            </select>
                                        </td>
                                        <td>
                                            <button
                                                class="btn btn-sm btn-ghost-red"
                                                @click="
                                                    removeTableParamColumn(
                                                        index
                                                    )
                                                "
                                            >
                                                <i class="ti ti-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button
                                id="addTableParamColumnButton"
                                class="btn btn-sm"
                                @click="addTableParamColumn()"
                            >
                                <i class="ti ti-plus"></i>
                                Adicionar coluna
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"

export default {
    name: "CustomNodeEditPage",
    data() {
        return {
            isNew: false,
            table: {},
            customNode: {
                execution_type: "custom_code",
                code_execution_content: `async function handler(msg, params) {
    var result = {}
    
    // seu código aqui

    return result
}
`,
                params: []
            },
            image_preview: null,
            modalEditOptions: null,
            currentFieldOptions: [],
            currentFieldTableParamColumns: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showBackButton = true

        if (this.$route.params.id) {
            var response = await Api.customNodes.get(this.$route.params.id)
            this.customNode = response.data
            this.image_preview = this.customNode.imageData
        } else {
            this.isNew = true
        }

        this.modalEditOptions = new bootstrap.Modal(
            document.getElementById("modal-edit-options"),
            {
                keyboard: false
            }
        )

        this.modalEditTableParamColumns = new bootstrap.Modal(
            document.getElementById("modal-edit-table-param-columns"),
            {
                keyboard: false
            }
        )

        this.initEditor()
    },
    methods: {
        async saveCustomNode() {
            try {
                this.validate()

                var response

                if (this.isNew) {
                    response = await Api.customNodes.create(this.customNode)
                } else {
                    response = await Api.customNodes.update(
                        this.customNode._id,
                        this.customNode
                    )
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Conector salvo com sucesso!"
                })

                if (response.data._id && this.isNew) {
                    this.$router.push("/custom-nodes/" + response.data._id)
                }
            } catch (error) {
                var message = error.message

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    message = error.response.data.error
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar: " + message
                })
            }
        },
        addField() {
            this.customNode.params.push({
                name: "",
                label: "",
                type: "string",
                default_value: "",
                is_required: false,
                options: [],
                columns: [],
                position: 0
            })

            // set focus on the first input of new line
            setTimeout(() => {
                var inputs = document.querySelectorAll('input[name="name"]')
                inputs[inputs.length - 1].focus()
            }, 50)
        },
        removeField(index) {
            this.customNode.params.splice(index, 1)
        },
        duplicateField(index) {
            var field = this.customNode.params[index]
            this.customNode.params.push({
                name: field.name,
                label: field.label,
                type: field.type,
                default_value: field.default_value,
                is_required: field.is_required,
                position: field.position
            })
        },
        updateImage(event) {
            if (event.target.files.length !== 0) {
                var reader = new FileReader()
                reader.onloadend = function () {
                    if (reader.result.length > 100000) {
                        EventBus.emit("message", {
                            type: "danger",
                            message: "A imagem não pode ser maior que 100Kb"
                        })
                        return
                    }

                    this.image_preview = reader.result
                    this.customNode.imageData = reader.result
                }.bind(this)
                reader.readAsDataURL(event.target.files[0])
            }
        },
        editOptions(field) {
            this.currentFieldOptions = field.options
            this.modalEditOptions.show()
        },
        addFieldOption() {
            this.currentFieldOptions.push({
                label: "",
                value: ""
            })
        },
        removeFieldOption(index) {
            this.currentFieldOptions.splice(index, 1)
        },

        editTableParamColumns(field) {
            this.currentFieldTableParamColumns = field.columns
            this.modalEditTableParamColumns.show()
        },
        addTableParamColumn() {
            this.currentFieldTableParamColumns.push({
                key: "",
                label: "",
                type: "text"
            })
        },
        removeTableParamColumn(index) {
            this.currentFieldTableParamColumns.splice(index, 1)
        },

        validate() {
            if (!this.customNode.name) {
                throw new Error("O nome do conector é obrigatório")
            }

            if (!this.customNode.description) {
                throw new Error("A descrição do conector é obrigatória")
            }
        },

        // code editor
        initEditor: function () {
            if (window.monacoEditor) {
                window.monacoEditor.dispose()
            }

            var editor = monaco.editor.create(
                document.getElementById("monacoEditorContainer"),
                {
                    value: this.customNode.code_execution_content,
                    theme: "vs-dark",
                    language: "javascript",
                    automaticLayout: true,
                    contextmenu: true,
                    minimap: {
                        enabled: true
                    },
                    padding: {
                        top: 10
                    }
                }
            )

            editor.onDidChangeModelContent((e) => {
                this.customNode.code_execution_content = editor.getValue()
            })

            // this.$watch('customLanguage', () => {
            //     monaco.editor.setModelLanguage(editor.getModel(), this.customLanguage);
            // })

            // set monaco editor to global variable to prevent memory leaks on Vue
            window.monacoEditor = editor

            // register new global shortcut (Ctrl/CMD + Shift + P)
            window.addEventListener("keydown", (e) => {
                if (
                    (e.ctrlKey || e.metaKey) &&
                    e.shiftKey &&
                    e.code === "KeyP"
                ) {
                    this.showCommandPalette()
                }
            })
        },

        showCommandPalette() {
            window.monacoEditor.focus()
            window.monacoEditor.trigger("", "editor.action.quickCommand", "")
        },

        exportCustomNode() {
            var data = JSON.parse(JSON.stringify(this.customNode))

            delete data.__v
            delete data._id

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(data))
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute(
                "download",
                this.slugify(this.customNode.name) + ".connector.json"
            )
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        },

        importCustomNode() {
            if (this.customNode._id) {
                if (
                    !confirm(
                        "Ao importar você substituirá o conector atual. Deseja continuar?"
                    )
                ) {
                    return
                }
            }

            var input = document.createElement("input")
            input.type = "file"
            input.accept = ".json"

            input.onchange = async (e) => {
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onload = async (e) => {
                    // TODO: validar se o arquivo é um connector válido (JSON Schema)

                    var data = JSON.parse(e.target.result)
                    var currentId = this.customNode._id

                    this.customNode = data
                    this.customNode._id = currentId

                    this.image_preview = this.customNode.imageData

                    this.initEditor()
                }
                reader.readAsText(file)
            }
            input.click()
        },

        slugify(str) {
            return str
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
        }
    }
}
</script>

<style scoped>
.text-right {
    text-align: right;
}
</style>
