<template>
    <div class="row mb-2" v-if="workspace.engine_url">
        <div class="col-2">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-auto">
                            <span
                                class="status-indicator status-indicator-animated"
                                v-bind:class="
                                    agent_up ? 'status-green' : 'status-red'
                                "
                            >
                                <span class="status-indicator-circle"></span>
                                <span class="status-indicator-circle"></span>
                                <span class="status-indicator-circle"></span>
                            </span>
                        </div>
                        <div class="col-auto">
                            <div class="text-muted">Status</div>
                            <div class="h5 mb-1">
                                {{ agent_up ? "Online" : "Offline" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="card">
                <div class="card-body">
                    <div class="text-muted">Flows em execução</div>
                    <div class="h5 mb-1">
                        {{
                            status.running_flows_count > 0
                                ? status.running_flows_count
                                : 0
                        }}
                        flow(s)
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="card">
                <div class="card-body">
                    <div class="text-muted">Jobs agendados</div>
                    <div class="h5 mb-1">{{ agent_scheduled_jobs }} job(s)</div>
                </div>
            </div>
        </div>
        <div class="col-2">
            <div class="card">
                <div class="card-body">
                    <div class="text-muted">Filas internas</div>
                    <div class="h5 mb-1">
                        <a href="/queues" target="_blank"
                            >{{ agent_registered_queues }} fila(s)
                            registradas</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card">
                <div class="card-body">
                    <div class="text-muted">Endpoint URL</div>
                    <div class="h5 mb-1">
                        <a v-bind:href="workspace.engine_url" target="_blank">{{
                            workspace.engine_url
                        }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
import { store } from "../store"

export default {
    name: "WorkspaceEngineStatus",
    data() {
        return {
            workspace: {},
            agent_up: false,
            agent_scheduled_jobs: 0,
            agent_registered_queues: 0,
            intervalRefresh: null,
            status: {}
        }
    },
    unmounted() {
        clearInterval(this.intervalRefresh)
    },
    async mounted() {
        console.log("WorkspaceEngineStatus mounted")
        this.workspace = store.workspace
        await this.loadAgentStatus()

        this.intervalRefresh = setInterval(async () => {
            await this.loadAgentStatus()
        }, 1000)
    },
    methods: {
        async loadAgentStatus() {
            axios
                .get(this.workspace.engine_url + "status")
                .then((response) => {
                    this.agent_up = response.data.running
                    this.agent_scheduled_jobs = response.data.scheduled_jobs
                    this.agent_registered_queues =
                        response.data.queues.registered_queues
                    this.status = response.data
                })
                .catch((error) => {
                    this.agent_up = false
                    this.agent_scheduled_jobs = 0
                })
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        }
    }
}
</script>
