<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="row g-2">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="col">
                                <h3 class="card-title">
                                    <i class="ti ti-box"></i>
                                    {{ isNew ? "Novo form" : "Editar form" }}
                                </h3>
                                <small class="text-muted" v-if="!isNew">ID: {{ form._id }}</small>
                            </div>
                            <div class="col-auto">
                                <button class="btn" @click="importForm()" v-if="isNew" id="importFormButton">
                                    <i class="ti ti-upload"></i>
                                    Importar form
                                </button>
                                <span>&nbsp;</span>
                                <button class="btn" @click="exportForm()" id="exportFormButton">
                                    <i class="ti ti-download"></i>
                                    Exportar este form
                                </button>
                            </div>
                            <div class="col-auto px-2">
                                <a :href="workspace.engine_url +
                                    'forms/' +
                                    form._id
                                    " class="btn btn-outline-primary" target="_blank" id="showFormButton">
                                    <i class="ti ti-eye"></i>
                                    Visualizar
                                </a>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-8">
                                    <label class="form-label required">Nome do formulário</label>
                                    <input type="text" v-model="form.name" class="form-control"
                                        placeholder="Exemplo: Solicitação de suporte de TI" id="nameInput" />
                                    <div class="form-hint">
                                        Visível apenas internamente no Workspace
                                    </div>
                                </div>
                                <div class="col-4">
                                    <label class="form-label required">Status</label>
                                    <select class="form-select" v-model="form.status" id="formStatusSelect">
                                        <option value="draft">Rascunho</option>
                                        <option value="published">
                                            Publicado
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a href="#tabs-fields" class="nav-link active" data-bs-toggle="tab"
                                            aria-selected="true" role="tab" tabindex="-1" id="formFieldsTab">
                                            <i class="ti ti-settings"></i>
                                            Campos do formulário
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a href="#tabs-view-config" class="nav-link" data-bs-toggle="tab"
                                            aria-selected="false" role="tab" id="viewConfigTab">
                                            <i class="ti ti-code"></i>
                                            Configurações de exibição
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div class="tab-pane active show" id="tabs-fields" role="tabpanel">
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-label">Campos do formulário</label>
                                                <p class="text-muted">
                                                    Aqui você deve inserir os
                                                    campos do seu formulário que
                                                    serão exibidos para
                                                    preenchimento pelos
                                                    usuários.
                                                </p>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th width="20%">
                                                                Tipo
                                                            </th>
                                                            <th>
                                                                Código do campo
                                                            </th>
                                                            <th>
                                                                Label/Título
                                                            </th>
                                                            <th>Obrigatório</th>
                                                            <th class="text-right">
                                                                Ações
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <draggable tag="tbody" v-model="form.fields" item-key="position"
                                                        v-bind="dragOptions" handle=".row-handler" @start="drag = true"
                                                        @end="drag = false" @change="
                                                            updateFieldsPosition()
                                                            ">
                                                        <template #item="{
                                                            element,
                                                            index
                                                        }">
                                                            <tr :class="element.type ==
                                                                    'separator'
                                                                    ? 'row-element-separator'
                                                                    : ''
                                                                ">
                                                                <td>
                                                                    <i class="ti ti-grip-vertical row-handler"></i>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        class="form-select form-select-sm fieldTypeSelect"
                                                                        v-model="element.type
                                                                            ">
                                                                        <option value="text">
                                                                            Texto
                                                                            simples
                                                                        </option>
                                                                        <option value="textarea">
                                                                            Texto
                                                                            longo
                                                                        </option>
                                                                        <option value="date">
                                                                            Data
                                                                        </option>
                                                                        <option value="datetime">
                                                                            Data
                                                                            e
                                                                            hora
                                                                        </option>
                                                                        <option value="checkbox">
                                                                            Caixa
                                                                            de
                                                                            checagem
                                                                            (Checkbox)
                                                                        </option>
                                                                        <option value="select">
                                                                            Opções
                                                                            de
                                                                            seleção
                                                                            (Dropdown)
                                                                        </option>
                                                                        <option value="radio">
                                                                            Opções
                                                                            de
                                                                            seleção
                                                                            (Radio)
                                                                        </option>
                                                                        <option value="multiple_checkbox">
                                                                            Múltipla
                                                                            seleção
                                                                            (Checkbox)
                                                                        </option>
                                                                        <option value="multiple_select">
                                                                            Múltipla
                                                                            seleção
                                                                            (Dropdown)
                                                                        </option>
                                                                        <option value="upload">
                                                                            Upload
                                                                            de
                                                                            arquivos
                                                                        </option>
                                                                        <option value="hidden">
                                                                            Texto
                                                                            oculto
                                                                        </option>
                                                                        <option value="separator">
                                                                            Separador/Título
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td v-if="
                                                                    element.type !==
                                                                    'separator'
                                                                ">
                                                                    <input type="text" name="name"
                                                                        class="form-control form-control-sm fieldNameInput"
                                                                        placeholder="field" v-model="element.name
                                                                            " />
                                                                </td>
                                                                <td v-if="
                                                                    element.type !==
                                                                    'separator'
                                                                ">
                                                                    <input type="text"
                                                                        class="form-control form-control-sm fieldLabelInput"
                                                                        placeholder="Label do campo" v-model="element.label
                                                                            " />
                                                                </td>
                                                                <td colspan="3" v-else>
                                                                    <input type="text"
                                                                        class="form-control form-control-sm fieldLabelInput"
                                                                        placeholder="Label do campo" v-model="element.label
                                                                            " />
                                                                </td>
                                                                <td v-if="
                                                                    element.type !==
                                                                    'separator'
                                                                ">
                                                                    <input type="checkbox"
                                                                        class="form-check-input fieldRequiredCheckbox"
                                                                        v-model="element.is_required
                                                                            " :disabled="element.type ==
                                                                            'separator'
                                                                            " />
                                                                </td>
                                                                <td class="text-right">
                                                                    <button class="btn btn-sm optionsButton" @click="
                                                                        editOptions(
                                                                            element
                                                                        )
                                                                        " :disabled="element.type ==
                                                                            'separator'
                                                                            ">
                                                                        <i class="ti ti-list"></i>
                                                                        Opções
                                                                    </button>
                                                                    <span>&nbsp;</span>
                                                                    <button class="btn btn-sm duplicateButton" @click="
                                                                        duplicateField(
                                                                            index
                                                                        )
                                                                        ">
                                                                        <i class="ti ti-copy"></i>
                                                                        Duplicar
                                                                    </button>
                                                                    <span>&nbsp;</span>
                                                                    <button
                                                                        class="btn btn-sm btn-ghost-red removeButton"
                                                                        @click="
                                                                            removeField(
                                                                                index
                                                                            )
                                                                            ">
                                                                        <i class="ti ti-trash"></i>
                                                                        Remover
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </draggable>
                                                </table>

                                                <button class="btn text-blue" @click="addField()" id="addFieldButton">
                                                    <i class="ti ti-plus"></i>
                                                    Adicionar campo
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="tabs-view-config" role="tabpanel">
                                        <!-- <div class="col-4 mb-3">
                                            <label class="form-label">Tipo de formulário <em>*</em></label>
                                            <select class="form-select" v-model="form.type">
                                                <option value="simple">Formulário simples</option>
                                                <option value="wizard">Wizard</option>
                                            </select>
                                        </div> -->
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Título de exibição do
                                                    formulário</label>
                                                <input type="text" v-model="form.title" class="form-control"
                                                    placeholder="Exemplo: Abra seu chamado" id="displayTitleInput" />
                                                <div class="form-hint">
                                                    <i class="ti ti-info-circle"></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Mensagem de exibição do
                                                    formulário</label>
                                                <textarea class="form-control" v-model="form.message"
                                                    placeholder="Exemplo: Preencha o formulário abaixo para abrir um chamado de suporte"
                                                    id="displayMessageInput"></textarea>
                                                <div class="form-hint">
                                                    <i class="ti ti-info-circle"></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Mensagem de sucesso após
                                                    envio</label>
                                                <textarea class="form-control" v-model="form.success_message
                                                    " placeholder="Exemplo: Formulário enviado com sucesso!"
                                                    id="successMessageInput"></textarea>
                                                <div class="form-hint">
                                                    <i class="ti ti-info-circle"></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-check">
                                                    <input class="form-check-input" v-model="form.show_back_button
                                                        " type="checkbox" id="showBackButtonCheckbox" />
                                                    <span class="form-check-label">
                                                        Exibir botão para novo
                                                        envio após a
                                                        conclusão</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-check">
                                                    <input class="form-check-input" v-model="form.wait_flow_result
                                                        " type="checkbox" id="waitFlowResultCheckbox" />
                                                    <span class="form-check-label">
                                                        Aguardar por resultado da execução do Flow e exibir ao
                                                        usuário</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-check">
                                                <input class="form-check-input" v-model="form.require_auth_pin" type="checkbox">
                                                <span class="form-check-label"> Exigir autenticação por PIN para acessar o formulário</span>
                                            </label>
                                        </div>
                                        <div class="row mb-3" v-if="form.require_auth_pin">
                                            <div class="col">
                                                <label class="form-label required">PIN de autenticação</label>
                                                <input type="text" v-model="form.auth_pin" maxlength="6" class="form-control"
                                                    placeholder="Exemplo: ABC123" />
                                                <div class="form-hint">
                                                    <i class="ti ti-info-circle"></i>
                                                    Use apenas números e letras. Máximo de 6 caracteres.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-primary" @click="saveForm()" id="saveFormButton">
                                        <i class="ti ti-device-floppy"></i>
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-blur fade" id="modal-edit-options" tabindex="-1" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Opções do campo
                            <span v-if="currentFieldEditOptions.label">"{{
                                currentFieldEditOptions.label.trim()
                            }}"</span>
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body pt-3">
                        <div class="modal-body-container">
                            <div class="row mb-3">
                                <div class="col-6">
                                    <label class="form-label">Valor padrão</label>
                                    <input type="text" class="form-control" v-model="currentFieldEditOptions.default_value
                                        " />
                                </div>
                            </div>
                            <div class="row mb-3" v-if="
                                canSetUploadOptions(currentFieldEditOptions)
                            ">
                                <div class="col-6">
                                    <label class="form-check">
                                        <input class="form-check-input" v-model="currentFieldEditOptions.upload_multiple
                                            " type="checkbox" />
                                        <span class="form-check-label">
                                            Permitir múltiplos arquivos</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row mb-3" v-if="
                                canSetUploadOptions(currentFieldEditOptions)
                            ">
                                <div class="col">
                                    <label class="form-label">Extensões permitidas</label>
                                    <input type="text" class="form-control" v-model="currentFieldEditOptions.upload_extensions
                                        " />
                                    <div class="form-hint">
                                        Valores separados por vírgula. Exemplo:
                                        <code>pdf,docx</code>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3" v-if="
                                canSetUploadOptions(currentFieldEditOptions)
                            ">
                                <div class="col">
                                    <label class="form-label required">Tamanho máximo dos arquivos (Mb)</label>
                                    <div class="row mb-1">
                                        <div class="col-3">
                                            <input type="number" class="form-control" v-model="currentFieldEditOptions.upload_maxsize
                                                " />
                                        </div>
                                    </div>
                                    <div class="form-hint">
                                        Máximo de <code>100 Mb</code> permitido
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3" v-if="
                                canApplyValidation(currentFieldEditOptions)
                            ">
                                <div class="col-6">
                                    <label class="form-label">Regra de validação</label>
                                    <select class="form-select" v-model="currentFieldEditOptions.validation
                                        ">
                                        <option value="">Nenhuma</option>
                                        <option value="email">E-mail</option>
                                        <option value="cpf">CPF</option>
                                        <option value="cnpj">CNPJ</option>
                                        <option value="telefone_br">
                                            Telefone (BR)
                                        </option>
                                        <option value="celular_br">
                                            Celular (BR)
                                        </option>
                                        <option value="date_br">
                                            Data (BR)
                                        </option>
                                        <option value="datetime_br">
                                            Data e horário (BR)
                                        </option>
                                        <option value="time">
                                            Horário/Duração de tempo
                                        </option>
                                        <option value="cep">CEP</option>
                                        <option value="url">URL</option>
                                        <option value="number">Número</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3" v-if="canApplyMask(currentFieldEditOptions)">
                                <div class="col-6">
                                    <label class="form-label">Aplicar máscara de digitação?</label>
                                    <select class="form-select" v-model="currentFieldEditOptions.mask">
                                        <option value="">Não</option>
                                        <option value="000.000.000-00">
                                            CPF
                                        </option>
                                        <option value="00.000.000/0000-00">
                                            CNPJ
                                        </option>
                                        <option value="(00) 0000-0000">
                                            Telefone (BR)
                                        </option>
                                        <option value="(00) 00000-0000">
                                            Celular (BR)
                                        </option>
                                        <option value="00/00/0000">
                                            Data (BR)
                                        </option>
                                        <option value="00/00/0000 00:00">
                                            Data e hora (BR)
                                        </option>
                                        <option value="00:00:00">
                                            Hora, minuto e segundo (BR)
                                        </option>
                                        <option value="00000-000">
                                            CEP (BR)
                                        </option>
                                        <option value="#.##0,00__reverse">
                                            Preço (BRL)
                                        </option>
                                        <option value="#,##0.00__reverse">
                                            Preço (USD)
                                        </option>
                                        <option value="custom">
                                            Personalizada
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6" v-if="
                                    currentFieldEditOptions.mask == 'custom'
                                ">
                                    <label class="form-label required">Máscara personalizada</label>
                                    <input type="text" class="form-control" v-model="currentFieldEditOptions.mask_custom
                                        " placeholder="Exemplo: 000.000.000" />
                                    <div class="form-hint">
                                        Use <code>0</code> para números,
                                        <code>S</code> para letras,
                                        <code>A</code> para alfanuméricos
                                        (letras ou números) e
                                        <code>#</code> para números variáveis
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <label class="form-label">Largura do componente (%)</label>
                                    <input type="number" class="form-control" v-model="currentFieldEditOptions.width_percent
                                        " />
                                </div>
                            </div>
                            <div v-if="canSetOptions(currentFieldEditOptions)">
                                <h4>Opções para seleção pelo usuário</h4>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <label class="form-label">Fonte de dados das opções</label>
                                        <select class="form-select" v-model="currentFieldEditOptions.options_source_type
                                            ">
                                            <option value="">
                                                Inserção manual
                                            </option>
                                            <option value="floui_database">
                                                Floui Database
                                            </option>
                                            <!-- <option value="flow_run_output">Saída de execução de Flow</option> -->
                                        </select>
                                    </div>
                                </div>

                                <div v-if="
                                    !currentFieldEditOptions.options_source_type
                                ">
                                    <table class="table table-hovered">
                                        <thead>
                                            <tr>
                                                <th>Label</th>
                                                <th>Valor</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(
                                                    option, index
                                                ) in currentFieldOptions" :key="option">
                                                <td>
                                                    <input v-model="option.label"
                                                        class="form-control form-control-sm" />
                                                </td>
                                                <td>
                                                    <input v-model="option.value"
                                                        class="form-control form-control-sm" />
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-ghost-red" @click="
                                                        removeFieldOption(
                                                            index
                                                        )
                                                        ">
                                                        <i class="ti ti-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button class="btn btn-sm" @click="addFieldOption()">
                                        <i class="ti ti-plus"></i>
                                        Adicionar opção
                                    </button>
                                </div>

                                <div v-if="
                                    currentFieldEditOptions.options_source_type ==
                                    'floui_database'
                                ">
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label class="form-label required">Tabela</label>
                                            <select class="form-select" v-model="currentFieldEditOptions.options_source_table
                                                ">
                                                <option v-for="table in database_tables" :value="table._id"
                                                    :key="table._id">
                                                    {{ table.name }}
                                                </option>
                                            </select>
                                            <div class="form-hint">
                                                No máximo 100 registros serão
                                                exibidos para seleção ao usuário
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label class="form-label required">Campo da tabela para o valor da
                                                seleção</label>
                                            <input type="text" class="form-control" v-model="currentFieldEditOptions.options_source_value_field
                                                " placeholder="Exemplo: _id" />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label class="form-label required">Campo da tabela para o nome da
                                                seleção (label visível ao
                                                usuário)</label>
                                            <input type="text" class="form-control" v-model="currentFieldEditOptions.options_source_label_field
                                                " placeholder="Exemplo: name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
table tr.row-element-separator td {
    /* azul claro */
    background: #f2f4f7;
}

table tr.row-element-separator td input {
    font-weight: bold;
}

.text-right {
    text-align: right;
}
</style>

<script>
import draggable from "vuedraggable"
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"

export default {
    name: "FormEditPage",
    components: {
        draggable
    },
    data() {
        return {
            drag: true,
            isNew: false,
            workspace: {},
            table: {},
            form: {
                status: "draft",
                fields: [],
                success_message: "O formulário foi enviado com sucesso!",
                show_back_button: true
            },
            modalEditOptions: null,
            currentFieldEditOptions: {},
            currentFieldOptions: [],
            currentFieldTableParamColumns: [],
            database_tables: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showBackButton = true
        this.workspace = store.workspace

        if (this.$route.params.id) {
            var response = await Api.forms.get(this.$route.params.id)
            this.form = response.data
        } else {
            this.isNew = true
        }

        this.modalEditOptions = new bootstrap.Modal(
            document.getElementById("modal-edit-options"),
            {
                keyboard: false
            }
        )

        this.loadDatabaseTables()
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    methods: {
        async saveForm() {
            try {
                this.validate()

                var response

                if (this.isNew) {
                    response = await Api.forms.create(this.form)
                } else {
                    response = await Api.forms.update(this.form._id, this.form)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Form salvo com sucesso!"
                })

                if (response.data._id && this.isNew) {
                    this.$router.push("/forms/edit/" + response.data._id)
                }
            } catch (error) {
                var message = error.message

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    message = error.response.data.error
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar: " + message
                })
            }
        },
        updateFieldsPosition() {
            // update fields position based on their index on array
            this.form.fields.forEach((field, index) => {
                field.position = index
            })
        },
        addField() {
            var newPosition = this.form.fields.length + 1
            this.form.fields.push({
                name: "",
                label: "",
                type: "text",
                default_value: "",
                is_required: false,
                mask: "",
                mask_custom: "",
                validation: "",
                upload_multiple: false,
                upload_extensions: "",
                upload_maxsize: 10,
                options: [],
                options_source_type: "",
                options_source_table: "",
                options_source_value_field: "_id",
                options_source_label_field: "name",
                position: newPosition,
                width_percent: 100
            })

            // set focus on the first input of new line
            setTimeout(() => {
                var inputs = document.querySelectorAll('input[name="name"]')
                inputs[inputs.length - 1].focus()
            }, 50)
        },
        removeField(index) {
            this.form.fields.splice(index, 1)
            this.updateFieldsPosition()
        },
        duplicateField(index) {
            var field = this.form.fields[index]
            this.form.fields.splice(
                index + 1,
                0,
                JSON.parse(JSON.stringify(field))
            )
            this.updateFieldsPosition()
        },

        loadDatabaseTables() {
            Api.database.tables.listAll().then((response) => {
                this.database_tables = response.data.items
            })
        },

        canSetOptions(field) {
            return (
                [
                    "select",
                    "radio",
                    "multiple_checkbox",
                    "multiple_select"
                ].indexOf(field.type) >= 0
            )
        },

        canApplyValidation(field) {
            return ["text", "textarea"].indexOf(field.type) >= 0
        },

        canApplyMask(field) {
            return ["text"].indexOf(field.type) >= 0
        },

        canSetUploadOptions(field) {
            return ["upload"].indexOf(field.type) >= 0
        },

        editOptions(field) {
            this.currentFieldEditOptions = field
            this.currentFieldOptions = field.options
            this.modalEditOptions.show()
        },
        addFieldOption() {
            this.currentFieldOptions.push({
                label: "",
                value: ""
            })
        },
        removeFieldOption(index) {
            this.currentFieldOptions.splice(index, 1)
        },

        validate() {
            if (!this.form.name) {
                throw new Error("O nome do form é obrigatório")
            }

            if (this.form.fields.length == 0) {
                throw new Error("É necessário adicionar pelo menos um campo")
            }

            this.form.fields.forEach((field, index) => {
                if (!field.name && field.type != "separator") {
                    throw new Error(
                        "O nome do campo " + (index + 1) + " é obrigatório"
                    )
                }

                if (!field.label && field.type != "separator") {
                    throw new Error(
                        "O label do campo " + (index + 1) + " é obrigatório"
                    )
                }

                if (this.canSetOptions(field)) {
                    // manual options
                    if (field.options_source_type == "") {
                        if (field.options.length == 0) {
                            throw new Error(
                                "É necessário adicionar pelo menos uma opção para o campo " +
                                (index + 1)
                            )
                        }

                        field.options.forEach((option, optionIndex) => {
                            if (!option.label) {
                                throw new Error(
                                    "O label da opção " +
                                    (optionIndex + 1) +
                                    " do campo " +
                                    (index + 1) +
                                    " é obrigatório"
                                )
                            }

                            if (!option.value) {
                                throw new Error(
                                    "O valor da opção " +
                                    (optionIndex + 1) +
                                    " do campo " +
                                    (index + 1) +
                                    " é obrigatório"
                                )
                            }
                        })
                    }

                    // floui database options
                    if (field.options_source_type == "floui_database") {
                        if (!field.options_source_table) {
                            throw new Error(
                                "É necessário selecionar uma tabela para o campo " +
                                (index + 1)
                            )
                        }

                        if (!field.options_source_value_field) {
                            throw new Error(
                                "É necessário selecionar uma coluna para o valor da opção para o campo " +
                                (index + 1)
                            )
                        }

                        if (!field.options_source_label_field) {
                            throw new Error(
                                "É necessário selecionar uma coluna para o label da opção para o campo " +
                                (index + 1)
                            )
                        }
                    }
                }
            })
        },

        exportForm() {
            var data = JSON.parse(JSON.stringify(this.form))

            delete data.__v
            delete data._id

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(data))
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute(
                "download",
                this.slugify(this.form.name) + ".form.json"
            )
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        },

        importForm() {
            if (this.form._id) {
                if (
                    !confirm(
                        "Ao importar você substituirá o form atual. Deseja continuar?"
                    )
                ) {
                    return
                }
            }

            var input = document.createElement("input")
            input.type = "file"
            input.accept = ".json"

            input.onchange = async (e) => {
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onload = async (e) => {
                    // TODO: validar se o arquivo é um form válido (JSON Schema)

                    var data = JSON.parse(e.target.result)
                    var currentId = this.form._id

                    this.form = data
                    this.form._id = currentId
                }
                reader.readAsText(file)
            }
            input.click()
        },

        slugify(str) {
            return str
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
        }
    }
}
</script>
