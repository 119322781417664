<template>
    <div>
        <h2>Redirecionando...</h2>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "Oauth2CallbackPage",
    async mounted() {
        var data = this.$route.query.data

        if (!data) {
            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: Dados de retorno inválidos"
            })
            return this.$router.push("/service-accounts")
        }

        try {
            data = JSON.parse(atob(data))
            await Api.serviceAccounts.updateOauth2(
                data.service_account_id,
                data
            )

            EventBus.emit("message", {
                type: "success",
                message: "Conta autorizada com sucesso!"
            })
        } catch (err) {
            var error_message =
                err.response.data.message ??
                err.response.data.error ??
                err.response.data

            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: " + error_message
            })
        }

        this.$router.push("/service-accounts")
    }
}
</script>
