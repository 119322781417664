<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <DbTablesList />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DbTablesList from "../components/DbTablesList"
import { store } from "../store"

export default {
    name: "DbTablesPage",
    components: {
        DbTablesList
    },
    data() {
        return {
            tables: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
    }
}
</script>
