<template>
    <div>
        <div class="row messages-container" v-if="messages.length">
            <div class="col">
                <div
                    v-for="message in messages"
                    class="alert"
                    v-bind:key="message.message"
                    v-bind:class="'alert-' + message.type"
                >
                    {{ message.message }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.messages-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}
body.live-debugger-active .messages-container {
    right: 440px;
}
.messages-container .alert {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
</style>

<script>
import EventBus from "../services/event-bus"

export default {
    name: "Messages",
    data() {
        return {
            lastMessageCreatedAt: null,
            messages: []
        }
    },
    mounted: function () {
        EventBus.on("message", (message) => {
            // scroll to top
            // window.scrollTo(0, 0);

            // if last message created at > 10 seconds, clear all
            if (
                this.lastMessageCreatedAt &&
                new Date().getTime() -
                    new Date(this.lastMessageCreatedAt).getTime() >
                    10000
            ) {
                this.messages = []
            }

            // if last messages is equal to current message, remove it
            if (
                this.messages.length &&
                this.messages[this.messages.length - 1].text === message.text
            ) {
                this.messages.pop()
            }

            // append new message
            this.messages.push(message)
            this.lastMessageCreatedAt = Date.now()

            setTimeout(() => {
                this.messages = []
            }, 5000)
        })
        EventBus.on("clear-messages", () => {
            console.log("clear-messages")
            this.messages = []
        })
    }
}
</script>
