<template>
    <div
        class="modal modal-blur fade"
        id="modal-queue-message-add"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Adicionar mensagem na fila "{{ queue.name }}"
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id="closeFormButton"
                    ></button>
                </div>
                <div class="modal-body pt-2">
                    <div class="modal-body-container">
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label required"
                                    >Conteúdo</label
                                >
                                <textarea
                                    class="form-control"
                                    rows="10"
                                    placeholder="Conteúdo da mensagem"
                                    v-model="message"
                                    id="messageContentInput"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <label class="form-label"
                                    >Delayed Message</label
                                >
                                <select
                                    class="form-select"
                                    v-model="delayed"
                                    id="delayedMessageSelect"
                                >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                            <div class="col-3" v-show="delayed == '1'">
                                <label class="form-label"
                                    >Delay em segundos</label
                                >
                                <input
                                    type="number"
                                    class="form-control"
                                    placeholder="Delay em segundos"
                                    v-model="delay_in_seconds"
                                    id="delayInSecondsInput"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        class="btn"
                        data-bs-dismiss="modal"
                        id="cancelButton"
                    >
                        Cancelar
                    </button>
                    <button
                        class="btn btn-primary ms-auto"
                        @click="save()"
                        id="saveQueueMessageButton"
                    >
                        <i class="ti ti-device-floppy"></i>
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/api"
import EventBus from "@/services/event-bus"

export default {
    name: "QueueMessageAdd",
    props: {
        queue: {
            type: Object
        }
    },
    data() {
        return {
            message: "",
            delayed: 0,
            delay_in_seconds: 0,
            modal: null
        }
    },
    mounted() {
        this.modal = new bootstrap.Modal(
            document.getElementById("modal-queue-message-add"),
            {
                keyboard: false
            }
        )
        this.modal.show()
    },
    methods: {
        async save() {
            try {
                this.validate()

                var response = await Api.queues.addJob(this.queue._id, {
                    message: this.message,
                    delayed: this.delayed,
                    delay_in_seconds: this.delay_in_seconds
                })

                this.$emit("added", response.data)

                EventBus.emit("message", {
                    type: "success",
                    message: "Mensagem adicionada à fila"
                })

                this.modal.hide()
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Erro ao adicionar mensagem à fila: " + error.message
                })
            }
        },
        validate() {
            if (!this.message) {
                throw new Error('O campo "Conteúdo" é obrigatório')
            }
        }
    }
}
</script>
