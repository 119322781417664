<template>
    <div>
        <div class="widget-content valign-middle text-center height-full">
            <apexchart
                v-if="chartTimeseries"
                width="100%"
                height="100%"
                type="bar"
                :options="chartOptions"
                :series="[{ name: config.label, data: chartTimeseries }]"
            ></apexchart>
        </div>

        <div class="widget-options" v-if="editMode">
            <i class="btn-icon ti ti-settings-filled" @click="showConfig()"></i>
            <i class="btn-icon ti ti-copy" @click="duplicate()"></i>
            <i
                class="btn-icon ti ti-trash text-red"
                @click="removeWidget()"
            ></i>
        </div>

        <WidgetConfigModal
            v-if="editMode && showWidgetConfig"
            :key="showWidgetConfig"
            :widget="widget"
            :defaults="config"
            :params="widgetParams"
            @refreshData="refreshData"
            @updateConfig="updateConfig"
            :flows="flows"
        />
    </div>
</template>

<style scoped>
.size-p {
    font-size: 1rem;
}
.size-m {
    font-size: 2rem;
}
.size-g {
    font-size: 3rem;
}
.height-full {
    height: 100%;
    overflow: hidden;
}
</style>

<script>
import moment from "moment-timezone"
import WidgetConfigModal from "./WidgetConfigModal.vue"

export default {
    name: "ExecutionHistogramWidget",
    props: {
        widget: Object,
        editMode: Boolean,
        flows: Array,
        widgetsQueryResult: Object
    },
    components: {
        WidgetConfigModal
    },
    data() {
        return {
            config: {
                periodType: "dashboard",
                flow_id: "",
                start_date: null,
                end_date: null,
                view_type: "count"
            },
            modalWidgetOptions: null,
            showWidgetConfig: null,
            queryData: {},
            flowName: null,
            chartOptions: {
                xaxis: {
                    type: "datetime",
                    labels: {
                        datetimeUTC: false
                    }
                },
                chart: {
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                }
            },
            chartTimeseries: [],
            widgetParams: [
                {
                    name: "flow_id",
                    type: "flow-selector",
                    label: "Selecione o Flow",
                    required: false,
                    refresh_on_update: true
                },
                {
                    name: "periodType",
                    type: "period",
                    label: "Período",
                    required: true,
                    refresh_on_update: true
                },
                {
                    name: "label",
                    type: "text",
                    label: "Legenda",
                    required: false
                }
            ]
        }
    },
    mounted() {
        moment.locale("pt-br")
        moment.tz.setDefault("America/Sao_Paulo")

        if (this.widget.config) {
            this.config = this.widget.config
        }

        this.$watch("widgetsQueryResult", this.updateQueryData, { deep: true })

        this.updateConfig()
        this.updateQueryData()
    },
    methods: {
        showConfig() {
            this.showWidgetConfig = Date.now()
        },
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        duplicate() {
            this.$emit("duplicateWidget", this.widget)
        },
        updateConfig() {
            this.config.view_type = "histogram"
            this.$emit("updateConfig", this.widget, this.config)
        },
        updateQueryData() {
            this.flowName = this.flows.find(
                (flow) => flow._id === this.config.flow_id
            )?.name
            this.queryData = this.widgetsQueryResult[this.widget.id]
            this.updateChartData()
        },
        refreshData() {
            this.$emit("refreshData")
        },
        numberFormat(number) {
            return new Intl.NumberFormat("pt-BR").format(number)
        },
        updateChartData() {
            if (this.queryData && this.queryData.timeseries) {
                this.chartTimeseries = this.queryData.timeseries.map((item) => {
                    return { x: moment(item.key).toDate(), y: item.doc_count }
                })
            }
        }
    }
}
</script>
