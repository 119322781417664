<template>
    <div>Redirecionando...</div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "AuthPage",
    data() {
        return {}
    },
    methods: {
        async processAuthRedirect() {
            try {
                localStorage.removeItem("user")
                localStorage.removeItem("token")
                localStorage.removeItem("tokenExpiration")
                localStorage.removeItem("workspace")

                var token = this.$route.query.token
                var data = JSON.parse(atob(token.split(".")[1]))

                localStorage.setItem("token", token)
                localStorage.setItem("tokenExpiration", data.exp * 1000)

                var userResponse = await Api.users.me()
                EventBus.emit("updated-user", userResponse.data)

                var workspaceResponse =
                    await Api.workspaces.getCurrentWorkspace()
                EventBus.emit("set-workspace", workspaceResponse.data)

                this.$router.push("/")
            } catch (error) {
                console.error(error)
            }
        }
    },
    mounted() {
        store.showSidebar = false
        store.showHeader = false
        EventBus.emit("set-header-title", "")

        this.processAuthRedirect()
    }
}
</script>
