<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="row mb-2">
                <div class="col">
                    <WorkspaceEngineStatus />
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="col">
                                <div class="row">
                                    <div class="col-auto">
                                        <DateRangePicker @periodChange="updatePeriodFilter"
                                            :dateStart="filters.date_start" :dateEnd="filters.date_end"
                                            :periodTypeDefault="filters.date_alias" :key="randomKey" />
                                    </div>
                                    <div class=" col-auto">
                                        <label class="form-label">
                                            <i class="ti ti-refresh"></i>
                                            Auto-refresh
                                        </label>
                                        <div class="form-selectgroup">
                                            <label class="form-selectgroup-item">
                                                <input type="radio" name="autorefresh" v-model="autorefresh" value="0"
                                                    class="form-selectgroup-input" id="offAutorefreshInput" />
                                                <span class="form-selectgroup-label">Off</span>
                                            </label>
                                            <label class="form-selectgroup-item">
                                                <input type="radio" name="autorefresh" v-model="autorefresh" value="10"
                                                    class="form-selectgroup-input" id="10sAutorefreshInput" />
                                                <span class="form-selectgroup-label">10s</span>
                                            </label>
                                            <label class="form-selectgroup-item">
                                                <input type="radio" name="autorefresh" v-model="autorefresh" value="30"
                                                    class="form-selectgroup-input" id="30sAutorefreshInput" />
                                                <span class="form-selectgroup-label">30s</span>
                                            </label>
                                            <label class="form-selectgroup-item">
                                                <input type="radio" name="autorefresh" v-model="autorefresh" value="60"
                                                    class="form-selectgroup-input" id="1mAutorefreshInput" />
                                                <span class="form-selectgroup-label">1m</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-auto mb-3">
                                        <label class="form-label">Outras opções</label>
                                        <button class="btn bg-blue-lt" @click="reloadLogs()" id="refreshLogsButton">
                                            <i class="ti ti-reload"></i>
                                            Atualizar
                                        </button>
                                        <span>&nbsp;</span>
                                        <button class="btn bg-muted-lt" @click="resetFilters()" id="resetFiltersButton">
                                            <i class="ti ti-x"></i>
                                            Limpar filtros
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Termo de busca</label>
                                        <input type="text" class="form-control" v-model="filter_query"
                                            @change="updateQueryFilter" placeholder="Buscar por termo"
                                            id="searchTermInput" />
                                    </div>
                                    <div class="col-3 mb-3">
                                        <label class="form-label">Execution ID</label>
                                        <input type="text" class="form-control" v-model="filter_execution_id"
                                            @change="updateExecutionIdFilter" placeholder="" id="executionIdInput" />
                                    </div>
                                    <div class="col-2 mb-3">
                                        <label class="form-label">Flow</label>
                                        <select class="form-select" v-model="filters.flow" id="flowSelect">
                                            <option value="all">
                                                Todos os Flows
                                            </option>
                                            <option v-for=" flow  in  flows " :value="flow._id" v-bind:key="flow._id">
                                                {{ flow.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-auto mb-3">
                                        <label class="form-label">Tipo do log</label>
                                        <div class="form-selectgroup">
                                            <label class="form-selectgroup-item">
                                                <input type="radio" v-model="filters.type" value="all"
                                                    class="form-selectgroup-input" id="allLogTypeInput" />
                                                <span class="form-selectgroup-label">Todos</span>
                                            </label>
                                            <label class="form-selectgroup-item">
                                                <input type="radio" v-model="filters.type" value="user"
                                                    class="form-selectgroup-input" id="userLogTypeInput" />
                                                <span class="form-selectgroup-label">Do usuário</span>
                                            </label>
                                            <label class="form-selectgroup-item">
                                                <input type="radio" v-model="filters.type" value="internal"
                                                    class="form-selectgroup-input" id="internalLogTypeInput" />
                                                <span class="form-selectgroup-label">Eventos internos</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body" v-if="logs.length">
                            <div>
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th class="message-col">
                                                Mensagem
                                            </th>
                                            <th class="execution-id-col">
                                                ID de execução
                                            </th>
                                            <th>Tipo do log</th>
                                            <th>Flow</th>
                                            <th>Timestamp</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for=" log  in  logs " v-bind:key="log._id">
                                            <td class="message-log">
                                                <small class="text-muted" v-show="log.data.label">{{ log.data.label
                                                    }}</small>
                                                <br v-show="log.data.label" />
                                                {{
                                                chunkMessage(
                                                    log.data.message
                                                )
                                            }}
                                            </td>
                                            <td class="execution-id-log">
                                                {{ log.data.execution_id }}
                                            </td>
                                            <td>
                                                <span class="badge bg-primary-lt">
                                                    {{
                                                logTypeName(
                                                    log.data.type
                                                )
                                            }}
                                                </span>
                                            </td>
                                            <td>
                                                {{ flowName(log.data.flow_id) }}
                                            </td>
                                            <td>
                                                {{
                                                formatDate(
                                                    log.data.timestamp
                                                )
                                            }}
                                            </td>
                                            <td>
                                                <button class="btn btn-sm showLogDetailsButton"
                                                    @click="showLogDetails(log)">
                                                    <i class="ti ti-eye"></i>
                                                    Detalhes
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer">
                            <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage"
                                :total="total" v-if="total" @pageChange="updatePage" />
                        </div>

                        <div class="card-body empty bg-muted-lt" v-if="!logs.length">
                            <p class="empty-title">
                                Nenhum log de atividade recebido para o período
                                e filtros selecionados.
                            </p>
                            <p class="empty-subtitle text-muted">
                                Tente alterar o período selecionado ou os
                                parâmetros de filtragem.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <LogDetails :log="selectedLog" :key="logKey" v-if="selectedLog" />
    </div>
</template>

<script>
import moment from "moment-timezone"
import Api from "../services/api"
import EventBus from "../services/event-bus"
import DateRangePicker from "../components/DateRangePicker"
import Pagination from "../components/Pagination"
import LogDetails from "../components/LogDetails"
import WorkspaceEngineStatus from "../components/WorkspaceEngineStatus"
import { store } from "../store"

export default {
    name: "MonitoringPage",
    components: {
        DateRangePicker,
        WorkspaceEngineStatus,
        Pagination,
        LogDetails
    },
    data() {
        return {
            logs: [],
            total: 0,
            totalPages: 0,
            showPerPage: 20,
            currentPage: 1,
            autoRefreshInterval: null,
            workspace: {},
            flows: [],
            timezone: "America/Sao_Paulo",
            locale: "pt-BR",
            showFilters: false,
            autorefresh: 0,
            randomKey: null,
            filters: {
                type: "all",
                flow: "all",
                query: "",
                date_alias: "1h"
            },
            filter_query: "",
            filter_execution_id: "",
            selectedLog: null,
            logKey: null,
            dateRangePickerKey: null
        }
    },
    computed: {
        paginationKey() {
            return this.currentPage + this.total
        }
    },
    unmounted() {
        clearInterval(this.autoRefreshInterval)
    },
    async mounted() {
        store.sidebarSize = "mini"
        store.showBackButton = true

        // apply URL query filters
        if (this.$route.query.filters) {
            try {
                this.filters = Object.assign(
                    this.filters,
                    JSON.parse(this.$route.query.filters)
                )
                this.filter_query = this.filters.query ?? ""
                this.filter_execution_id = this.filters.execution_id ?? ""
                this.randomKey = Math.random()
            } catch (e) {
                this.$router.push({
                    query: {
                        page: this.currentPage,
                        filters: null
                    }
                })
            }
        }

        this.workspace = store.workspace

        var flowsResponse = await Api.flows.list(1, 1000, {}, ["name"])
        this.flows = flowsResponse.data.items

        // logs request
        this.currentPage = parseInt(this.$route.query.page || 1)
        await this.loadLogs(this.currentPage, this.showPerPage, this.filters)

        // autorefresh results
        this.$watch(
            () => this.autorefresh,
            async (value) => {
                if (value > 0) {
                    this.autoRefreshInterval = setInterval(async () => {
                        this.randomKey = Math.random()
                        await this.loadLogs(
                            this.currentPage,
                            this.showPerPage,
                            this.filters
                        )
                    }, value * 1000)
                } else {
                    clearInterval(this.autoRefreshInterval)
                }
            }
        )

        // when filters change
        this.$watch(
            () => this.filters,
            async (filters) => {
                this.$router.push({
                    query: {
                        page: this.currentPage,
                        filters: JSON.stringify(this.filters)
                    }
                })
            },
            { deep: true }
        )

        this.$watch("filters.workspace", async () => {
            // deselect flow
            this.filters.flow = "all"
        })

        // title
        EventBus.emit("set-header-title", "Logs de monitoramento")
    },
    methods: {
        loadLogs: async function (page, showPerPage, filters) {
            var logsResponse = await Api.monitoring.getAllLogs(
                page,
                showPerPage,
                filters
            )
            this.logs = logsResponse.data.items
            this.total = logsResponse.data.total
            this.totalPages = Math.ceil(this.total / this.showPerPage)
        },
        async reloadLogs() {
            this.randomKey = Math.random()
            await this.loadLogs(
                this.currentPage,
                this.showPerPage,
                this.filters
            )
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        toggleFilters() {
            this.showFilters = !this.showFilters
        },
        showLogDetails(log) {
            this.selectedLog = log.data

            this.selectedLog._id = log._id
            this.selectedLog.flow_name = this.flowName(this.selectedLog.flow_id)
            this.selectedLog.type_name = this.logTypeName(this.selectedLog.type)
            this.selectedLog.timestamp_formatted = this.formatDate(
                this.selectedLog.timestamp
            )

            this.logKey = Math.random()
        },
        flowName(flow_id) {
            var flow = this.flows.filter((w) => w._id == flow_id)
            return flow[0] ? flow[0].name : ""
        },
        logTypeName(type) {
            switch (type) {
                case "user":
                    return "Usuário"
                case "internal":
                    return "Evento interno"
                default:
                    return "Todos"
            }
        },
        updatePeriodFilter(value, range = null) {
            this.filters.date_alias = value
            this.filters.date_start = null
            this.filters.date_end = null

            if (value == "custom" && range) {
                this.filters.date_start = range.date_start
                this.filters.date_end = range.date_end
            }

            this.randomKey = Math.random()
        },
        updatePage(page) {
            if (page > this.totalPages) page = this.totalPages

            this.currentPage = parseInt(page)
            this.$router.push({
                query: {
                    page: this.currentPage,
                    filters: JSON.stringify(this.filters)
                }
            })
        },
        updateQueryFilter() {
            this.currentPage = 1
            this.filters.query = this.filter_query
        },
        updateExecutionIdFilter() {
            this.currentPage = 1
            this.filters.execution_id = this.filter_execution_id
        },
        resetFilters() {
            this.currentPage = 1
            this.filters = {
                type: "all",
                flow: "all",
                query: "",
                date_alias: "1h",
                execution_id: ""
            }
            this.filter_query = ""
        },
        chunkMessage(message) {
            if (typeof message == "string" && message.length > 200) {
                return message.substring(0, 200) + "..."
            }
            return message
        }
    }
}
</script>

<style scoped>
.message-col {
    width: 40%;
    overflow-wrap: anywhere;
}

.execution-id-log {
    width: 20%;
    overflow-wrap: anywhere;
}
</style>
