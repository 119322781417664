<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    Remover conector personalizado
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover o conector
                                    <strong>{{ customNode.name }}</strong
                                    >? <br />
                                    <span
                                        >Esta ação não poderá ser
                                        desfeita.</span
                                    >
                                </p>
                                <div class="form-group">
                                    <label class="form-label"
                                        >Para confirmar a remoção, digite abaixo
                                        o texto
                                        <span class="bg-red-lt px-1">{{
                                            customNode.name
                                        }}</span
                                        >:</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="customNodeNameConfirmation"
                                        id="confirmationInput"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a
                                    href="/custom-nodes/"
                                    class="btn"
                                    id="cancelAndBackButton"
                                >
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a
                                    v-on:click="removeCustomNode"
                                    class="btn btn-danger"
                                    id="removeNodeButton"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover o conector
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "CustomNodeDeletePage",
    data() {
        return {
            customNodeNameConfirmation: "",
            customNode: {}
        }
    },
    async mounted() {
        var id = this.$route.params.id
        var response = await Api.customNodes.get(id)
        this.customNode = response.data
    },
    methods: {
        async removeCustomNode() {
            var id = this.$route.params.id
            try {
                if (this.validate()) {
                    await Api.customNodes.delete(id)

                    this.$router.push("/custom-nodes")

                    EventBus.emit("message", {
                        type: "success",
                        message: "Conector removido com sucesso!"
                    })
                }
            } catch (error) {
                EventBus.emit("message", {
                    type: "error",
                    message: "Erro ao remover conector:" + error
                })
            }
        },
        validate() {
            if (this.customNode.name !== this.customNodeNameConfirmation) {
                EventBus.emit("message", {
                    type: "warning",
                    message:
                        "O nome do conector não confere com o nome digitado!"
                })

                return false
            }
            return true
        }
    }
}
</script>
