<template>
    <div class="page-wrapper">
        <div class="row mt-2">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-1">
                            <div class="col">
                                <input v-model="dashboard.name" class="form-control" placeholder="Nome do dashboard"
                                    id="dashboardNameInput" />
                            </div>
                            <div class="col-auto">
                                <span class="dropdown">
                                    <button class="btn dropdown-toggle" data-bs-toggle="dropdown" id="addWidgetButton">
                                        <i class="ti ti-box"></i>
                                        Adicionar widget
                                    </button>
                                    <div class="dropdown-menu">
                                        <button class="dropdown-item" @click="addWidget('text_html')"
                                            id="textHTMLWidgetButton">
                                            <i class="ti ti-box"></i>
                                            Texto/HTML
                                        </button>
                                        <button class="dropdown-item" @click="
                                    addWidget('execution_count')
                                    " id="executionCountWidgetButton">
                                            <i class="ti ti-box"></i>
                                            Contagem de execuções
                                        </button>
                                        <button class="dropdown-item" @click="
                                    addWidget('execution_histogram')
                                    " id="executionGraphButton">
                                            <i class="ti ti-box"></i>
                                            Gráfico de execuções (histograma)
                                        </button>
                                        <button class="dropdown-item" @click="addWidget('execution_time')"
                                            id="executionTimeButton">
                                            <i class="ti ti-box"></i>
                                            Tempo de execução
                                        </button>
                                        <button class="dropdown-item" @click="addWidget('log_count')"
                                            id="logsCountButton">
                                            <i class="ti ti-box"></i>
                                            Contagem de logs
                                        </button>
                                        <button class="dropdown-item" @click="addWidget('log_list')"
                                            id="logsListButton">
                                            <i class="ti ti-box"></i>
                                            Lista de logs
                                        </button>
                                        <button class="dropdown-item" @click="addWidget('log_histogram')"
                                            id="logsGraphButton">
                                            <i class="ti ti-box"></i>
                                            Gráfico de logs (histograma)
                                        </button>
                                        <button class="dropdown-item" @click="addWidget('database_list')"
                                            id="databaseEntitiesListButton">
                                            <i class="ti ti-box"></i>
                                            Lista de registros do Database
                                        </button>
                                    </div>
                                </span>
                            </div>
                            <div class="col-auto" v-if="isNew">
                                <button class="btn" @click="importDashboard()" id="importDashboardButton">
                                    <i class="ti ti-upload"></i>
                                    Importar
                                </button>
                            </div>
                            <div class="col-auto">
                                <button class="btn" @click="refreshData()" id="refreshDataButton">
                                    <i class="ti ti-refresh"></i>
                                    Atualizar dados
                                </button>
                            </div>
                            <div class="col-auto" v-show="!isNew">
                                <button class="btn btn-danger" @click="cancel()" id="cancelButton">
                                    Cancelar
                                </button>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary" @click="saveDashboard()" id="saveDashboardButton">
                                    <i class="ti ti-device-floppy"></i>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <div class="dashboard-grid-loader" v-show="!dashboardLoaded">
                    <div class="container container-slim py-5">
                        <div class="text-center">
                            <div class="text-muted mb-3">
                                Carregando widgets...
                            </div>
                            <div class="progress progress-sm">
                                <div class="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" v-show="dashboardLoaded">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="dashboard-grid-container">
                                    <span class="no-widgets" v-if="!widgets.length">Insira widgets para exibição
                                        aqui</span>
                                    <Widget v-for="widget in widgets" :widget="widget"
                                        :widgetsQueryResult="widgetsQueryResult" :key="widget.id" :id="widget.id"
                                        :gs-id="widget.id" :gs-x="widget.x || 0" :gs-y="widget.y || 0"
                                        :gs-min-w="widget.minW || 1" :gs-min-h="widget.minH || 1" :gs-w="widget.w"
                                        :gs-h="widget.h" :editMode="true" :flows="flows" :tables="tables"
                                        @updateConfig="updateWidgetConfig" @removeWidget="removeWidget"
                                        @duplicateWidget="duplicateWidget" @refreshData="refreshData"
                                        @refreshWidgetData="refreshWidgetData" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dashboard-grid-container {
    min-height: 134px;
    border: 1px solid #f0f0f0;
}

.dashboard-grid-container .no-widgets {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999;
}

.dashboard-grid-container .grid-stack-item {
    border: 1px solid #f0f0f0;
}
</style>

<script>
import moment from "moment-timezone"
import "gridstack/dist/gridstack.min.css"
import "gridstack/dist/gridstack-extra.min.css"
import "@vueup/vue-quill/dist/vue-quill.snow.css"

import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"
import { GridStack } from "gridstack"

// widgets
import Widget from "../components/dashboard-widgets/Widget.vue"

export default {
    name: "DashboardEditPage",
    components: {
        // eslint-disable-next-line vue/no-unused-components
        Widget
    },
    data() {
        return {
            isNew: false,
            table: {},
            dashboard: {
                name: "",
                data: []
            },
            dashboardLoaded: false,
            grid: null,
            image_preview: null,
            modalEditOptions: null,
            currentFieldOptions: [],
            currentFieldTableParamColumns: [],
            widgets: [],
            flows: [],
            tables: [],
            widgetsQueryResult: {},
            filters: {
                periodType: null,
                start_date: null,
                end_date: null
            }
        }
    },
    async mounted() {
        moment.tz.setDefault("America/SaoPaulo")

        store.showSidebar = true
        store.showBackButton = true
        store.sidebarSize = "mini"
        EventBus.emit("set-header-title", "Editar dashboard")

        if (this.$route.params.id) {
            var response = await Api.dashboards.get(this.$route.params.id)
            this.dashboard = response.data
            this.widgets = response.data.data
        } else {
            this.isNew = true
        }

        await this.loadFlows()
        await this.loadTables()
        this.initEditor()

        this.filters.periodType = "today"
        this.$watch(
            "filters",
            () => {
                this.refreshData()
            },
            { deep: true }
        )
    },
    methods: {
        async initEditor() {
            this.grid = GridStack.init(
                {
                    column: 12,
                    cellHeight: 50
                },
                ".dashboard-grid-container"
            )
            await this.$nextTick()

            if (this.widgets.length > 0) {
                this.widgets.forEach((w) => (w.initialized = true))
            }

            this.grid.on("resize", (e, el) => {
                this.widgets.find((w) => w.id == el.id).w =
                    el.getAttribute("data-gs-width")
                this.widgets.find((w) => w.id == el.id).h =
                    el.getAttribute("data-gs-height")
            })

            await this.$nextTick()
            await this.refreshData()

            this.dashboardLoaded = true
        },

        async loadFlows() {
            var response = await Api.flows.list(1, 1000, {}, ["name"])

            this.flows = response.data.items.map((f) => {
                return {
                    _id: f._id,
                    name: f.name
                }
            })
        },

        async loadTables() {
            var response = await Api.database.tables.list(1, 1000)

            this.tables = response.data.items.map((f) => {
                return {
                    _id: f._id,
                    name: f.name
                }
            })
        },

        parseViewOptions(config) {
            var viewOptions = JSON.parse(JSON.stringify(config)) // clone

            if (config.periodType) {
                if (viewOptions.periodType == "today") {
                    viewOptions.start_date = moment()
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment()
                        .endOf("day")
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                } else if (viewOptions.periodType == "dashboard") {
                    viewOptions.start_date = null
                    viewOptions.end_date = null
                } else if (viewOptions.periodType == "custom") {
                    viewOptions.start_date = moment(
                        viewOptions.start_date
                    ).format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment(viewOptions.end_date).format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    )
                } else {
                    var period = viewOptions.periodType.split("_")[0]
                    var type = viewOptions.periodType.split("_")[1]

                    viewOptions.start_date = moment()
                        .subtract(period, type)
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment().format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    )
                }
            }

            return viewOptions
        },

        async refreshData() {
            var clonedWidgets = JSON.parse(JSON.stringify(this.widgets))

            // parse view options for each widget config
            clonedWidgets.forEach((w) => {
                if (w.config) {
                    w.config = this.parseViewOptions(w.config)
                }
            })

            var response = await Api.dashboards.query(null, {
                data: clonedWidgets
            })
            this.widgetsQueryResult = response.data
        },

        async refreshWidgetData(widget, config = {}) {
            var viewOptions = this.parseViewOptions(this.filters)
            viewOptions.widgetUserOptions = config

            var clonedWidget = JSON.parse(JSON.stringify(widget))
            var response = await Api.dashboards.query(
                null,
                { data: [clonedWidget] },
                viewOptions
            )
            this.widgetsQueryResult[widget.id] = response.data[widget.id]
        },

        async addWidget(type) {
            var randomId = Math.random().toString(36).substring(7)
            this.widgets.push({
                id: "widget-" + randomId,
                initialized: false,
                type: type,
                x: 0,
                y: 1000,
                w: 2,
                minW: 2,
                h: 2
            })

            await this.$nextTick()
            this.widgets.forEach((w) => this.makeWidget(w))

            this.refreshData()
        },

        makeWidget(widget) {
            if (!widget.initialized) {
                const elSelector = `#${widget.id}`
                widget.initialized = true
                return this.grid.makeWidget(elSelector)
            }
        },

        updateWidgetConfig(widget, config) {
            var index = this.widgets.findIndex((w) => w.id == widget.id)
            this.widgets[index].config = config
        },

        removeWidget(widget) {
            var index = this.widgets.findIndex((w) => w.id == widget.id)
            this.widgets.splice(index, 1)

            this.grid.removeWidget("#" + widget.id)
        },

        async duplicateWidget(widget) {
            widget = JSON.parse(JSON.stringify(widget))

            var randomId = Math.random().toString(36).substring(7)
            this.widgets.push({
                id: "widget-" + randomId,
                initialized: false,
                type: widget.type,
                x: 0,
                y: 1000,
                w: widget.w,
                minW: widget.minW,
                h: widget.h,
                config: widget.config
            })

            await this.$nextTick()
            this.widgets.forEach((w) => this.makeWidget(w))
            this.refreshData()
        },

        async cancel() {
            this.$router.push("/dashboards/view/" + this.dashboard._id)
        },

        async importDashboard() {
            var input = document.createElement("input")
            input.type = "file"
            input.accept = ".json"

            input.onchange = async (e) => {
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onload = async (e) => {
                    var data = JSON.parse(e.target.result)
                    this.dashboard = data
                    this.dashboard.name = this.dashboard.name + " (importado)"
                    this.widgets = data.data
                    this.dashboardLoaded = true

                    await this.$nextTick()
                    this.widgets.forEach((w) => this.makeWidget(w))

                    this.refreshData()
                }
                reader.readAsText(file)
            }
            input.click()
        },

        async saveDashboard() {
            try {
                var widgetsData = this.getWidgetsData()
                this.validate()

                var response
                this.dashboard.data = widgetsData

                if (this.isNew) {
                    response = await Api.dashboards.create(this.dashboard)
                } else {
                    response = await Api.dashboards.update(
                        this.dashboard._id,
                        this.dashboard
                    )
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Dashboard salvo com sucesso!"
                })

                this.$router.push("/dashboards/view/" + response.data._id)
            } catch (error) {
                var message = error.message

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    message = error.response.data.error
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar: " + message
                })
            }
        },

        validate() {
            if (!this.dashboard.name) {
                throw new Error("O nome do dashboard é obrigatório")
            }
        },

        getWidgetsData() {
            var gridData = this.grid.save()
            var widgetsData = []

            gridData.forEach((gd) => {
                var widget = JSON.parse(
                    JSON.stringify(this.widgets.find((w) => w.id == gd.id))
                )
                widget = Object.assign({}, widget, gd)
                widget.content = ""
                widget.config = widget.config || {}
                delete widget.initialized
                widgetsData.push(widget)
            })

            return widgetsData
        }
    }
}
</script>
