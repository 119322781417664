<template>
    <div>
        <div class="card" v-if="variablesList.length">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-code"></i>
                        Variáveis do workspace
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        @click="newVariable()"
                        data-bs-target="#modal-variable-form"
                        data-bs-toggle="modal"
                        class="btn btn-primary"
                        id="newVariableButton"
                    >
                        <i class="ti ti-plus"></i>
                        Criar variável
                    </button>
                </div>
            </div>
            <div>
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Chave</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="variable in variablesList"
                            v-bind:key="variable._id"
                        >
                            <td>{{ variable.name }}</td>
                            <td>
                                <code>{{ variable.key }}</code>
                            </td>
                            <td>{{ formatDate(variable.updated_at) }}</td>
                            <td>
                                <button
                                    @click="editVariable(variable)"
                                    class="btn btn-sm editVariableButton"
                                    data-bs-target="#modal-variable-form"
                                    data-bs-toggle="modal"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar
                                </button>
                                <span>&nbsp;</span>
                                <button
                                    @click="removeVariable(variable)"
                                    class="btn btn-sm removeVariableButton"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong>{{ variablesList.length }} variáveis</strong>
                <br />
            </div>
        </div>

        <div class="card" v-if="!variablesList.length">
            <div class="card-header">
                <h3 class="card-title">Variáveis do workspace</h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">Nenhuma variáveis criado ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar sua primeira variável!
                    </p>
                    <div class="empty-action">
                        <button
                            @click="newVariable()"
                            data-bs-target="#modal-variable-form"
                            data-bs-toggle="modal"
                            class="btn btn-primary"
                            id="newVariableButton"
                        >
                            <i class="ti ti-plus"></i>
                            Criar variável
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <VariableForm
            :action="variableFormAction"
            :data="variableFormData"
            @save="saveVariable"
        />
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import VariableForm from "../components/VariableForm"

export default {
    name: "VariablesPage",
    components: {
        VariableForm
    },
    data() {
        return {
            variableFormAction: "create",
            variableFormData: null,
            variablesList: [],
            workspace_id: null
        }
    },
    async mounted() {
        this.workspace_id = this.$route.params.workspace_id
        await this.loadVariables()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadVariables() {
            await Api.variables.list().then((response) => {
                this.variablesList = response.data
            })
        },
        newVariable() {
            this.variableFormAction = "create"
            this.variableFormData = {}
        },
        editVariable(variable) {
            this.variableFormAction = "edit"
            this.variableFormData = variable
        },
        async removeVariable(variable) {
            try {
                if (confirm("Deseja realmente remover a variável?") == false)
                    return

                await Api.variables.delete(variable._id)

                EventBus.emit("message", {
                    type: "success",
                    message: "Variável removida com sucesso"
                })

                await this.loadVariables()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao remover a variável"
                })
            }
        },
        async saveVariable(data) {
            try {
                if (this.variableFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.variables.create(data)
                } else {
                    await Api.variables.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Variável salva com sucesso"
                })

                await this.loadVariables()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar a variável"
                })
            }
        }
    }
}
</script>
