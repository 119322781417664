<template>
    <h2 class="page-title">
        <a href="#" @click="goToAccountPanel">
            <img
                src="@/assets/logo-purple-white.svg"
                class="logo"
                width="160"
                alt="Floui.io - Integração sem limites"
            />
        </a>
    </h2>
</template>

<script>
import { store } from "../store"
export default {
    name: "HeaderLogo",
    methods: {
        goToAccountPanel() {
            window.location.href = store.accountPanelUrl
        }
    }
}
</script>
