<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2 col-xl-8">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-alert-triangle"></i>
                                        Editar regra de alerta
                                    </h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-check form-switch required">
                                                    <input class="form-check-input" type="checkbox" v-model="alertRule.enabled
                                                        " />
                                                    <span class="form-check-label">Habilitar regra</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Nome da regra</label>
                                                <input type="text" class="form-control" placeholder=""
                                                    v-model="alertRule.name" />
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Tipo da regra</label>
                                                <select class="form-select" v-model="alertRule.type">
                                                    <option value="match_log">
                                                        Match de logs
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <label class="form-label required">Ação de alerta</label>
                                                <select class="form-select" v-model="alertRule.action">
                                                    <option value="email">
                                                        E-mail
                                                    </option>
                                                    <option value="webhook">
                                                        Webhook
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-12 mb-3" v-if="alertRule.action == 'email'
                                                        ">
                                                <label class="form-label required">E-mail para
                                                    recebimento</label>
                                                <input type="text" class="form-control" placeholder="" v-model="alertRule.action_email_to
                                                        " />
                                            </div>
                                            <div class="col-12" v-if="alertRule.action == 'email'
                                                        ">
                                                <label class="form-label">Mensagem adicional para o
                                                    e-mail</label>
                                                <textarea class="form-control" placeholder="" v-model="alertRule.action_email_message
                                                        "></textarea>
                                            </div>
                                            <div class="col-auto" v-if="alertRule.action ==
                                                        'webhook'
                                                        ">
                                                <label class="form-label required">Método para Webhook</label>
                                                <select class="form-select" v-model="alertRule.action_webhook_method
                                                        ">
                                                    <option value="post">
                                                        POST
                                                    </option>
                                                    <option value="put">
                                                        PUT
                                                    </option>
                                                    <option value="delete">
                                                        DELETE
                                                    </option>
                                                    <option value="patch">
                                                        PATCH
                                                    </option>
                                                    <option value="get">
                                                        GET
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col" v-if="alertRule.action ==
                                                        'webhook'
                                                        ">
                                                <label class="form-label required">URL do webhook</label>
                                                <input type="text" class="form-control" placeholder="https://" v-model="alertRule.action_webhook_url
                                                        " />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <h4>Configuração da regra</h4>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-auto" v-if="alertRule.type ==
                                                        'match_log'
                                                        ">
                                                <label class="form-label required">Buscar em</label>
                                                <select class="form-select" v-model="alertRule.match_log_type
                                                        ">
                                                    <option value="label">
                                                        Label do log (texto)
                                                    </option>
                                                    <option value="message">
                                                        Mensagem do log (texto)
                                                    </option>
                                                    <option value="message_json">
                                                        Mensagem do log (JSON
                                                        field)
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-3" v-if="alertRule.type ==
                                                        'match_log' &&
                                                        alertRule.match_log_type ==
                                                        'message_json'
                                                        ">
                                                <label class="form-label required">Campo do JSON na
                                                    mensagem</label>
                                                <div class="row g-2">
                                                    <div class="col">
                                                        <input type="text" class="form-control" placeholder="" v-model="alertRule.match_log_json_field
                                                        " />
                                                    </div>
                                                </div>
                                                <small class="form-text text-muted">
                                                    Exemplos:
                                                    <code>event</code>,
                                                    <code>order.status</code>
                                                </small>
                                            </div>
                                            <div class="col" v-if="alertRule.type ==
                                                        'match_log'
                                                        ">
                                                <label class="form-label required">Conteúdo para match</label>
                                                <div class="row g-2">
                                                    <div class="col">
                                                        <input type="text" class="form-control" placeholder="" v-model="alertRule.match_log_content
                                                        " />
                                                    </div>
                                                    <!-- <div class="col-auto">
                                                        <button class="btn">
                                                            <i class="ti ti-search"></i>
                                                        </button>
                                                    </div> -->
                                                </div>
                                                <small class="form-text text-muted">
                                                    Utiliza texto simples ou
                                                    expressões regulares.
                                                    Exemplos:
                                                    <code>flow_run_exception</code>,
                                                    <code>Falha de
                                                        integração</code>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-auto">
                                                <label class="form-label required">Janela de captura</label>
                                                <select class="form-select" v-model="alertRule.timewindow_seconds
                                                        ">
                                                    <option value="60">
                                                        1 minuto
                                                    </option>
                                                    <option value="300">
                                                        5 minutos
                                                    </option>
                                                    <option value="600">
                                                        10 minutos
                                                    </option>
                                                    <option value="1800">
                                                        30 minutos
                                                    </option>
                                                    <option value="3600">
                                                        1 hora
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col" v-if="alertRule.type ==
                                                        'match_log'
                                                        ">
                                                <label class="form-label required">Filtrar por logs gerados
                                                    por</label>
                                                <select class="form-select" v-model="alertRule.flow_id">
                                                    <option value="">
                                                        Qualquer Flow
                                                    </option>
                                                    <option :value="flow._id" v-for="flow in flowList" :key="flow._id">
                                                        {{ flow.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-auto align-right">
                                        <button class="btn btn-primary" @click="saveAlertRule">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar regra de alerta
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "AlertRuleEditPage",
    data() {
        return {
            alertRule: {
                name: "Nova regra de alerta",
                type: "match_log",
                enabled: true,
                action: "email",
                action_email_to: "",
                action_webhook_method: "post",
                action_webhook_url: "",
                match_log_type: "message",
                match_log_content: "",
                flow_id: null,
                timewindow_seconds: 300
            },
            flowList: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true
        store.backUrl = "/alert-rules"

        // new
        if (this.$route.params.id) {
            var response = await Api.alertRules.get(this.$route.params.id)
            this.alertRule = response.data

            if (!this.alertRule.flow_id) {
                this.alertRule.flow_id = ""
            }
        }

        var flowResponse = await Api.flows.list(1, 1000, {}, ["name"])
        this.flowList = flowResponse.data.items
    },
    methods: {
        async saveAlertRule() {
            try {
                // new
                if (!this.$route.params.id) {
                    await Api.alertRules.create(this.alertRule)
                } else {
                    // update
                    await Api.alertRules.update(
                        this.alertRule._id,
                        this.alertRule
                    )
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "A regra de alerta foi salva com sucesso!"
                })

                this.$router.push("/alert-rules")
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Ocorreu um erro ao salvar a regra de alerta: " +
                        message
                })
            }
        }
    }
}
</script>
